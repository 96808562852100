// client/src/pages/CustomerTable.js

import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../components/Table';
import StatusIndicator from '../../components/StatusIndicator';

const CustomerTable = ({ customers, setTopBoxes, onEdit, onDelete, onDetails, onBulkSelect }) => {
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);

    const headers = [
        { label: 'OptionalID', key: 'optionalID', width: '15%' },
        { label: 'ID', key: 'customerId', width: '15%' },
        { label: 'Name', key: 'customerName', width: '20%' },
        { label: 'Set-Top Box Number', key: 'boxNumber', width: '20%' },
        {
            label: 'Status',
            key: 'status',
            width: '15%',
            render: (status) => (
                <div className="flex items-center space-x-2">
                    <StatusIndicator status={status} />
                    <span>{status}</span>
                </div>
            )
        },
        { label: 'Subscription', key: 'subscriptionPlanName', width: '20%' },
        { label: 'Actions', key: 'actions', width: '10%' }
    ];

    const getCustomerSetTopBoxNumber = (customerId) => {
        const setTopBox = setTopBoxes.find(box => box.customer && box.customer._id === customerId);
        return setTopBox ? setTopBox.boxNumber : 'N/A';
    };

    const getCustomerSetTopBoxStatus = (customerId) => {
        const setTopBox = setTopBoxes.find(box => box.customer && box.customer._id === customerId);
        return setTopBox ? setTopBox.status : 'UnAssigned';
    };

    const handleBulkSelect = useCallback((newSelectedRows) => {
        setSelectedCustomerIds(newSelectedRows);
        onBulkSelect(newSelectedRows);
    }, [onBulkSelect]);

    useEffect(() => {
    }, [selectedCustomerIds]);

    return (
        <Table
            headers={headers}
            data={customers.map(customer => ({
                ...customer,
                id: customer._id,
                boxNumber: getCustomerSetTopBoxNumber(customer._id),
                status: getCustomerSetTopBoxStatus(customer._id),
                actions: (
                    <div className="flex justify-center space-x-2">
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(customer);
                            }}
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                        >
                            Edit
                        </button>
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                onDelete(customer);
                            }}
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                        >
                            Delete
                        </button>
                    </div>
                )
            }))}
            disableFilters={['actions']}
            disableSorting={['actions']}
            onCellClick={(row, key) => {
                if (key !== 'actions') {
                    onDetails(row);
                }
            }}
            selectable={true}
            onBulkSelect={handleBulkSelect}
            selectedRows={selectedCustomerIds}
        />
    );
};

export default CustomerTable;