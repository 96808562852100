// client/src/components/Billing/BillingPaymentSummary.js

import React from 'react';
import StatsCard from '../StatsCard';
import { FaMoneyBillWave, FaCheckCircle, FaExclamationTriangle } from 'react-icons/fa';

const BillingPaymentSummary = ({ totalDue, totalPaid, outstanding }) => {
    return (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-2"> {/* Reduced gap for smaller cards */}
            <StatsCard
                title="Total Due"
                value={`₹${totalDue.toFixed(2)}`}
                icon={<FaMoneyBillWave className="text-blue-500" />}
                cardSize="small"  // Smaller card size
                titleColor="text-blue-600"
                valueColor="text-blue-800"
                titleSize="text-lg"  // Reduced title size
                valueSize="text-2xl" // Reduced value size
                padding="p-3"  // Reduced padding for a smaller appearance
            />
            <StatsCard
                title="Total Paid"
                value={`₹${totalPaid.toFixed(2)}`}
                icon={<FaCheckCircle className="text-green-500" />}
                cardSize="small"
                titleColor="text-green-600"
                valueColor="text-green-800"
                titleSize="text-lg"
                valueSize="text-2xl"
                padding="p-3"
            />
            <StatsCard
                title="Outstanding Amount"
                value={`₹${outstanding.toFixed(2)}`}
                icon={<FaExclamationTriangle className={outstanding > 0 ? 'text-red-500' : 'text-green-500'} />}
                cardSize="small"
                titleColor={outstanding > 0 ? 'text-red-600' : 'text-green-600'}
                valueColor={outstanding > 0 ? 'text-red-800' : 'text-green-800'}
                titleSize="text-lg"
                valueSize="text-2xl"
                padding="p-3"
            />
        </div>
    );
};

export default BillingPaymentSummary;


