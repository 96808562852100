import React, { useState, useEffect, useContext } from 'react';
import Layout from '../Layout';
import Modal from '../../components/Modal';
import CustomerForm from '../../components/CustomerForm';
import AuthContext from '../../context/AuthContext'; // Import AuthContext
import debounce from 'lodash.debounce';
import { toast } from 'react-toastify'; // Import toast for notifications
import * as XLSX from 'xlsx'; // Import XLSX for sample file generation

function CustomerUpdate({ match }) {
    const customerId = match.params.id;
    const { authAxios } = useContext(AuthContext); // Use AuthContext for authenticated Axios instance
    const [showModal, setShowModal] = useState(true);
    const [customerData, setCustomerData] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [setTopBoxes, setSetTopBoxes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchNodes = debounce(async (query) => {
        try {
            setLoading(true);
            const response = await authAxios.get('/api/nodes', {
                params: { query }
            });
            setNodes(response.data);
        } catch (err) {
            console.error('Error fetching nodes:', err);
            setError('Failed to load nodes. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, 300);

    const fetchSetTopBoxes = debounce(async (query) => {
        try {
            setLoading(true);
            const response = await authAxios.get('/api/settopboxes', {
                params: { query }
            });
            setSetTopBoxes(response.data);
        } catch (err) {
            console.error('Error fetching set top boxes:', err);
            setError('Failed to load set top boxes. Please try again later.');
        } finally {
            setLoading(false);
        }
    }, 300);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [customerResponse, nodesResponse, setTopBoxesResponse] = await Promise.all([
                    authAxios.get(`/api/customers/${customerId}`),
                    authAxios.get('/api/nodes'),
                    authAxios.get('/api/settopboxes')
                ]);
                setCustomerData(customerResponse.data);
                setNodes(nodesResponse.data);
                setSetTopBoxes(setTopBoxesResponse.data);
            } catch (err) {
                setError('Failed to fetch data. Please try again.');
                console.error('Error fetching data:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [customerId, authAxios]);

    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = async (formData) => {
        try {
            await authAxios.put(`/api/customers/${customerId}`, formData);
            toast.success('Customer Updated Successfully');
            handleCloseModal();
        } catch (err) {
            setError('Failed to update customer. Please try again.');
            toast.error('Failed to update customer. Please try again.');
            console.error('Error updating customer:', err);
        }
    };


    if (loading) return <Layout><div className="p-4">Loading...</div></Layout>;
    if (error) return <Layout><div className="p-4 text-red-500">{error}</div></Layout>;

    return (
        <Layout>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Update Customer Details</h1>
                {customerData && (
                    <Modal
                        isOpen={showModal}
                        onRequestClose={handleCloseModal}
                        contentLabel="Update Customer"
                    >
                        <CustomerForm
                            isUpdate={true}
                            customerData={customerData}
                            nodes={nodes}
                            setTopBoxes={setTopBoxes}
                            fetchNodes={fetchNodes}
                            fetchSetTopBoxes={fetchSetTopBoxes}
                            onSubmit={handleSubmit}
                            onClose={handleCloseModal}
                        />
                    </Modal>
                )}
            </div>
        </Layout>
    );
}

export default CustomerUpdate;
