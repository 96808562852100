// client/src/pages/Customer/CustomerActivityLog.js

import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import Timeline from '../../components/Timeline';
import Loader from '../../components/Loader';
import '../../styles/timeline.css';

const CustomerActivityLog = ({ customerId }) => {
    const { authAxios } = useContext(AuthContext);
    const [logs, setLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchLogs = async () => {
            try {
                const res = await authAxios.get(`/api/customers/${customerId}/activity-log`);
                setLogs(res.data);
            } catch (err) {
                console.error('Error fetching customer activity log:', err);
            } finally {
                setLoading(false);
            }
        };

        if (customerId) {
            fetchLogs();
        }
    }, [customerId]);

    if (loading) {
        return <Loader />;
    }

    return (
        <div className="activity-log-container p-4 space-y-6">
            <h2 className="text-2xl font-semibold mb-6">Customer Activity Log</h2>
            <div className="timeline-wrapper"> {/* No inline styles needed here */}
                <Timeline logs={logs} />
            </div>
        </div>
    );
};

export default CustomerActivityLog;




