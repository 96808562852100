// client/src/components/CustomerForm.js

import React, { useState, useEffect, useCallback, useContext } from 'react';
import debounce from 'lodash.debounce';
import AuthContext from '../context/AuthContext';

const CustomerForm = ({ isUpdate, customerData, onClose, onSubmit }) => {
    const { authAxios } = useContext(AuthContext); // Get the axios instance from context
    const [formData, setFormData] = useState({
        customerId: '',
        customerName: '',
        addressLine1: '',
        addressLine2: '',
        phoneNumber: '',
        status: 'Paid',
        subscriptionPlan: '',
        monthlySubscriptionFee: '',
        latitude: '',
        longitude: '',
        setTopBox: '',
        setTopBoxId: '',
        setTopBoxProvider: '',
        boxNumber: '',
        node: '',
        nodeId: '',
        nodeNumber: ''
    });
    const [setTopBoxes, setSetTopBoxes] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (isUpdate && customerData) {
            setFormData({
                customerId: customerData.customerId || '',
                customerName: customerData.customerName || '',
                addressLine1: customerData.addressLine1 || '',
                addressLine2: customerData.addressLine2 || '',
                phoneNumber: customerData.phoneNumber || '',
                status: customerData.status || 'Paid',
                subscriptionPlan: customerData.subscriptionPlan || '',
                monthlySubscriptionFee: customerData.monthlySubscriptionFee || '',
                latitude: customerData.latitude || '',
                longitude: customerData.longitude || '',
                setTopBox: customerData.boxNumber || '',
                setTopBoxId: customerData.setTopBox || '',
                setTopBoxProvider: customerData.setTopBoxProvider || '',
                boxNumber: customerData.boxNumber || '',
                node: customerData.connectNode?.nodeNumber || '',
                nodeId: customerData.connectNode?._id || '',
                nodeNumber: customerData.connectNode?.nodeNumber || ''
            });
        }
    }, [isUpdate, customerData]);

    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            try {
                const response = await authAxios.get('/api/subscriptionplans');
                setSubscriptionPlans(response.data);
            } catch (err) {
                console.error('Error fetching subscription plans:', err);
                setError('Failed to load subscription plans. Please try again.');
            }
        };
        fetchSubscriptionPlans();
    }, [authAxios]);

    const fetchSetTopBoxes = useCallback(debounce(async (query) => {
        try {
            setLoading(true);
            const response = await authAxios.get('/api/settopboxes', {
                params: { query }
            });
            const unassignedSetTopBoxes = response.data.filter(box => box.status === 'Not Assigned');
            setSetTopBoxes(unassignedSetTopBoxes);
        } catch (err) {
            console.error('Error fetching set top boxes:', err);
            setError('Failed to load set top boxes. Please try again.');
        } finally {
            setLoading(false);
        }
    }, 300), [authAxios]);

    const fetchNodes = useCallback(debounce(async (query) => {
        try {
            setLoading(true);
            const response = await authAxios.get('/api/nodes', {
                params: { query }
            });
            setNodes(response.data);
        } catch (err) {
            console.error('Error fetching nodes:', err);
            setError('Failed to load nodes. Please try again.');
        } finally {
            setLoading(false);
        }
    }, 300), [authAxios]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'subscriptionPlan') {
            const selectedPlan = subscriptionPlans.find(plan => plan._id === value);
            if (selectedPlan) {
                setFormData({
                    ...formData,
                    subscriptionPlan: selectedPlan._id,
                    monthlySubscriptionFee: selectedPlan.monthlyFee
                });
            }
        }
    };

    const handleSetTopBoxChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, setTopBox: value, boxNumber: value });
        fetchSetTopBoxes(value);
    };

    const handleNodeChange = (e) => {
        const { value } = e.target;
        setFormData({ ...formData, node: value });
        fetchNodes(value);
    };

    const handleGetGPS = () => {
        if (navigator.geolocation) {
            const options = {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0
            };

            navigator.geolocation.getCurrentPosition(
                (position) => {
                    setFormData({
                        ...formData,
                        latitude: position.coords.latitude.toString(),
                        longitude: position.coords.longitude.toString()
                    });
                },
                (error) => {
                    console.error("Error getting GPS coordinates:", error);
                    switch (error.code) {
                        case error.PERMISSION_DENIED:
                            setError("User denied the request for Geolocation.");
                            break;
                        case error.POSITION_UNAVAILABLE:
                            setError("Location information is unavailable.");
                            break;
                        case error.TIMEOUT:
                            setError("The request to get user location timed out.");
                            break;
                        case error.UNKNOWN_ERROR:
                            setError("An unknown error occurred.");
                            break;
                        default:
                            setError("An error occurred while fetching GPS coordinates.");
                    }
                },
                options
            );
        } else {
            setError("Geolocation is not supported by this browser.");
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);
        try {
            const dataToSubmit = {
                customerId: formData.customerId,
                customerName: formData.customerName,
                addressLine1: formData.addressLine1,
                addressLine2: formData.addressLine2,
                phoneNumber: formData.phoneNumber,
                status: formData.status,
                subscriptionPlan: formData.subscriptionPlan,
                monthlySubscriptionFee: formData.monthlySubscriptionFee ? parseFloat(formData.monthlySubscriptionFee) : undefined,
                latitude: formData.latitude ? parseFloat(formData.latitude) : undefined,
                longitude: formData.longitude ? parseFloat(formData.longitude) : undefined,
                setTopBox: formData.setTopBoxId,
                setTopBoxProvider: formData.setTopBoxProvider,
                boxNumber: formData.boxNumber,
                connectNode: formData.nodeId,
                nodeNumber: formData.nodeNumber
            };

            await onSubmit(dataToSubmit);

            onClose();
        } catch (err) {
            console.error('Error submitting form:', err);
            setError('Failed to submit form. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="max-h-[75vh] overflow-y-auto p-4">
            {/* Form fields */}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerId">
                    Customer ID <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    name="customerId"
                    id="customerId"
                    value={formData.customerId}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerName">
                    Customer Name <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    name="customerName"
                    id="customerName"
                    value={formData.customerName}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressLine1">
                    Address Line 1 <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    name="addressLine1"
                    id="addressLine1"
                    value={formData.addressLine1}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="addressLine2">
                    Address Line 2
                </label>
                <input
                    type="text"
                    name="addressLine2"
                    id="addressLine2"
                    value={formData.addressLine2}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="phoneNumber">
                    Phone Number <span className="text-red-500">*</span>
                </label>
                <input
                    type="tel"
                    name="phoneNumber"
                    id="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                    Status
                </label>
                <select
                    name="status"
                    id="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="Paid">Paid</option>
                    <option value="Unpaid">Unpaid</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="subscriptionPlan">
                    Subscription Plan
                </label>
                <select
                    name="subscriptionPlan"
                    id="subscriptionPlan"
                    value={formData.subscriptionPlan}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="">Select Plan</option>
                    {subscriptionPlans.map(plan => (
                        <option key={plan._id} value={plan._id}>{plan.planName}</option>
                    ))}
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="monthlySubscriptionFee">
                    Monthly Subscription Fee
                </label>
                <input
                    type="number"
                    name="monthlySubscriptionFee"
                    id="monthlySubscriptionFee"
                    value={formData.monthlySubscriptionFee}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    readOnly // Make this field read-only
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="latitude">
                    Latitude
                </label>
                <input
                    type="text"
                    name="latitude"
                    id="latitude"
                    value={formData.latitude}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="longitude">
                    Longitude
                </label>
                <input
                    type="text"
                    name="longitude"
                    id="longitude"
                    value={formData.longitude}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <button
                    type="button"
                    onClick={handleGetGPS}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Get GPS
                </button>
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="setTopBox">
                    Set Top Box
                </label>
                <input
                    type="text"
                    name="setTopBox"
                    id="setTopBox"
                    value={formData.setTopBox}
                    onChange={handleSetTopBoxChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {setTopBoxes.length > 0 && (
                    <div
                        className="absolute bg-white border border-gray-300 rounded w-full mt-1 shadow-lg z-10 max-h-32 overflow-y-auto">
                        {setTopBoxes.map((box) => (
                            <div
                                key={box._id}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        setTopBox: box.boxNumber,
                                        setTopBoxId: box._id,
                                        boxNumber: box.boxNumber,
                                        setTopBoxProvider: box.setTopBoxProvider
                                    });
                                    setSetTopBoxes([]);
                                }}
                            >
                                {box.boxNumber} - {box.setTopBoxProvider}
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className="mb-4 relative">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="node">
                    Connect Node
                </label>
                <input
                    type="text"
                    name="node"
                    id="node"
                    value={formData.node}
                    onChange={handleNodeChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
                {nodes.length > 0 && (
                    <div
                        className="absolute bg-white border border-gray-300 rounded w-full mt-1 shadow-lg z-10 max-h-32 overflow-y-auto">
                        {nodes.map((node) => (
                            <div
                                key={node._id}
                                className="p-2 cursor-pointer hover:bg-gray-100"
                                onClick={() => {
                                    setFormData({
                                        ...formData,
                                        node: node.nodeNumber,
                                        nodeId: node._id,
                                        nodeNumber: node.nodeNumber
                                    });
                                    setNodes([]);
                                }}
                            >
                                {node.nodeNumber} - {node.status}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {error && <p className="text-red-500 mb-4">{error}</p>}
            <div className="flex items-center justify-between pt-4">
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    disabled={loading}
                >
                    {loading ? 'Submitting...' : (isUpdate ? 'Update' : 'Register')}
                </button>
                <button
                    type="button"
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                    onClick={onClose}
                >
                    Cancel
                </button>
            </div>
        </form>
    );
};

export default CustomerForm;
