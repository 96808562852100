// client/src/components/MapComponent.js

import React, { useEffect, useRef, useState } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';
import PropTypes from 'prop-types'; // To ensure proper prop types
import MapMarkers from '../pages/MapMarkers'; // This could be refactored too, if needed

function MapComponent({
                          customers,
                          nodes,
                          showNodes,
                          showCustomers,
                          showConnections,
                          lineColor,
                          mapCenter,
                          initialZoom = 13,
                          zoomInOnLoad = true
                      }) {
    const mapRef = useRef();
    const [zoomLevel, setZoomLevel] = useState(initialZoom);

    const handleZoomIn = () => {
        if (mapRef.current) {
            const map = mapRef.current;
            const currentZoom = map.getZoom();
            const newZoom = currentZoom + 2.5;
            map.flyTo(map.getCenter(), newZoom, {
                animate: true,
                duration: 1.5, // Duration in seconds for the transition
            });
            setZoomLevel(newZoom);
        }
    };

    useEffect(() => {
        if (zoomInOnLoad) {
            const timer = setTimeout(() => {
                handleZoomIn();
            }, 500);

            return () => clearTimeout(timer);
        }
    }, [mapCenter, zoomInOnLoad]);

    return (
        <MapContainer
            center={mapCenter || [11.1271, 78.6569]} // Use provided or default coordinates
            zoom={zoomLevel}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
        >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapMarkers
                customers={customers}
                nodes={nodes}
                showNodes={showNodes}
                showCustomers={showCustomers}
                showConnections={showConnections}
                lineColor={lineColor}
            />
        </MapContainer>
    );
}

// Define default props
MapComponent.defaultProps = {
    initialZoom: 13,
    zoomInOnLoad: true,
    mapCenter: [11.1271, 78.6569], // Tamil Nadu coordinates as default
};

// Define prop types
MapComponent.propTypes = {
    customers: PropTypes.array.isRequired,
    nodes: PropTypes.array.isRequired,
    showNodes: PropTypes.bool,
    showCustomers: PropTypes.bool,
    showConnections: PropTypes.bool,
    lineColor: PropTypes.string,
    mapCenter: PropTypes.arrayOf(PropTypes.number),
    initialZoom: PropTypes.number,
    zoomInOnLoad: PropTypes.bool,
};

export default MapComponent;

