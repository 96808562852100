// client/src/context/AuthContext.js

import React, { createContext, useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { startRegistration, startAuthentication } from '@simplewebauthn/browser';
import {toast} from "react-toastify";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [token, setToken] = useState(localStorage.getItem('token') || sessionStorage.getItem('token'));

    const authAxios = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    });

    // Set up interceptor to add token to every request
    authAxios.interceptors.request.use((config) => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, (error) => {
        return Promise.reject(error);
    });

    const fetchUserData = useCallback(async () => {
        if (token) {
            try {
                const response = await authAxios.get('/api/users/me');
                console.log('User data fetched successfully:', response.data);
                setUser(response.data);
            } catch (err) {
                console.error('Error fetching user data:', err);
                localStorage.removeItem('token');
                sessionStorage.removeItem('token');
                setToken(null);
                setUser(null);
            }
        }
        setLoading(false);
    }, [token]);

    useEffect(() => {
        fetchUserData();
    }, [fetchUserData]);

    const login = async (username, password, keepSignedIn) => {
        setLoading(true);
        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, { username, password });
            const newToken = res.data.token;

            if (keepSignedIn) {
                localStorage.setItem('token', newToken);
            } else {
                sessionStorage.setItem('token', newToken);
            }

            setToken(newToken);
            await fetchUserData();
            return newToken;
        } catch (err) {
            console.error('Login failed:', err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
        setToken(null);
        setUser(null);
        setLoading(false);
    };

    // WebAuthn Registration
    const registerWebAuthn = async (username, pin) => {
        try {
            const response = await authAxios.post('/api/users/register-webauthn', { username, pin });
            const options = response.data;

            console.log('Received registration options:', options);

            if (!options || Object.keys(options).length === 0) {
                throw new Error('Empty response from server');
            }

            const attestationResponse = await startRegistration(options);

            console.log('Sending attestation response:', attestationResponse);
            await authAxios.post('/api/users/verify-webauthn-registration', {
                username,
                response: attestationResponse,
            });

            toast.success('WebAuthn registration successful');
        } catch (err) {
            console.error('WebAuthn registration failed:', err);
            toast.error('Failed to register WebAuthn');
            throw err;
        }
    };

    const loginWebAuthn = async (username) => {
        setLoading(true);
        try {
            console.log('Attempting WebAuthn login for user:', username);
            const response = await authAxios.post('/api/users/login-webauthn', { username });
            console.log('Received response from server:', response.data);

            if (response.data.authMethod === 'PIN') {
                return await pinLoginFallback(username);
            }

            if (response.data.authMethod === 'WebAuthn') {
                if (!response.data.options || Object.keys(response.data.options).length === 0) {
                    throw new Error('Received empty WebAuthn options from server');
                }

                // Start the WebAuthn authentication process
                const assertionResponse = await startAuthentication(response.data.options);
                console.log('Assertion response:', assertionResponse);

                // Verify the authentication with the server
                const verificationResponse = await authAxios.post('/api/users/verify-webauthn-login', {
                    username,
                    response: assertionResponse
                });

                const { token } = verificationResponse.data;

                handleSuccessfulLogin(token);
                console.log('WebAuthn login successful');
                return token;
            }

            throw new Error('Unexpected authentication method');
        } catch (err) {
            console.error('WebAuthn login failed:', err);
            if (err.name === 'NotAllowedError') {
                toast.error('WebAuthn authentication was cancelled or failed. Please try again.');
            } else if (err.response && err.response.data && err.response.data.message) {
                toast.error(`Login failed: ${err.response.data.message}`);
            } else {
                toast.error('Login failed. Please try again.');
            }
            handleLoginError(err);
            throw err;
        } finally {
            setLoading(false);
        }
    };

    const pinLoginFallback = async (username) => {
        const pin = prompt('Please enter your PIN:');
        if (!pin) {
            setLoading(false);
            throw new Error('PIN entry cancelled');
        }
        try {
            const response = await authAxios.post('/api/users/verify-webauthn-login', { username, pin });
            const { token } = response.data;
            handleSuccessfulLogin(token);
            console.log('PIN login successful');
            return token;
        } catch (err) {
            console.error('PIN login failed:', err);
            toast.error('PIN authentication failed. Please try again.');
            throw err;
        }
    };

    const handleSuccessfulLogin = (newToken) => {
        localStorage.setItem('token', newToken);
        setToken(newToken);
        fetchUserData();
        toast.success('Login successful');
    };

    const handleLoginError = (err) => {
        if (err.response) {
            switch (err.response.status) {
                case 404:
                    toast.error('User not found. Please check your credentials.');
                    break;
                case 400:
                    toast.error(err.response.data.message || 'Invalid input. Please try again.');
                    break;
                case 422:
                    if (err.response.data.code === 'WEBAUTHN_NOT_REGISTERED') {
                        return pinLoginFallback(err.response.data.customerId);
                    }
                    toast.error(err.response.data.message || 'WebAuthn not set up. Please try again.');
                    break;
                default:
                    toast.error('An error occurred. Please try again later.');
            }
        } else {
            toast.error('An error occurred. Please try again later.');
        }
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, loading, authAxios, registerWebAuthn, loginWebAuthn, pinLoginFallback,}}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
