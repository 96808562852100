// client/src/page/SetTopBox/SetTopBoxForm.js

import React from 'react';
import Select from 'react-select';
import Modal from '../../components/Modal';
import QRCodeComponent from '../../components/QRCodeComponent';

const SetTopBoxForm = ({
                           formData,
                           handleChange,
                           handleSelectChange,
                           handleSubmit,
                           modalMode,
                           customers,
                           nodes,
                           handleCloseModal,
                           showModal // Add this prop to control the visibility of the modal
                       }) => {
    return (
        <Modal
            isOpen={showModal} // Control modal visibility
            onRequestClose={handleCloseModal} // Handle modal close action
            contentLabel={modalMode === 'create' ? 'Add Set Top Box' : 'Edit Set Top Box'}
        >
            <form onSubmit={handleSubmit}>
                {modalMode === 'create' ? (
                    <>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="boxNumber">
                                Box Number <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="boxNumber"
                                id="boxNumber"
                                value={formData.boxNumber}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                                required
                            />
                        </div>
                        <div>
                            {/* QR Code Display */}
                            {formData.boxNumber && (
                                <QRCodeComponent value={formData.boxNumber} />
                            )}
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="setTopBoxProvider">
                                Provider <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                name="setTopBoxProvider"
                                id="setTopBoxProvider"
                                value={formData.setTopBoxProvider}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="optionalId">
                                Optional ID
                            </label>
                            <input
                                type="text"
                                name="optionalId"
                                id="optionalId"
                                value={formData.optionalId}
                                onChange={handleChange}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                                Status
                            </label>
                            <Select
                                name="status"
                                id="status"
                                value={{ value: formData.status, label: formData.status }}
                                onChange={(selectedOption) =>
                                    handleSelectChange(selectedOption, { name: 'status' })
                                }
                                options={[
                                    { value: 'Active', label: 'Active' },
                                    { value: 'Inactive', label: 'Inactive' },
                                    { value: 'Maintenance', label: 'Maintenance' },
                                    { value: 'Not Assigned', label: 'Not Assigned' }
                                ]}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="boxNumber">
                                Box Number
                            </label>
                            <input
                                type="text"
                                name="boxNumber"
                                id="boxNumber"
                                value={formData.boxNumber}
                                readOnly
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-gray-100 cursor-not-allowed leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customerId">
                                Select Customer
                            </label>
                            <Select
                                name="customerId"
                                id="customerId"
                                value={customers.find(c => c._id === formData.customerId) ? {
                                    value: formData.customerId,
                                    label: `${customers.find(c => c._id === formData.customerId).customerName} (${customers.find(c => c._id === formData.customerId).customerId})`
                                } : null}
                                onChange={(selectedOption) =>
                                    handleSelectChange(selectedOption, { name: 'customerId' })
                                }
                                options={customers.map((customer) => ({
                                    value: customer._id,
                                    label: `${customer.customerName} (${customer.customerId})`
                                }))}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                isClearable={true}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nodeId">
                                Select Node
                            </label>
                            <Select
                                name="nodeId"
                                id="nodeId"
                                value={nodes.find(n => n._id === formData.nodeId) ? {
                                    value: formData.nodeId,
                                    label: nodes.find(n => n._id === formData.nodeId).nodeNumber
                                } : null}
                                onChange={(selectedOption) =>
                                    handleSelectChange(selectedOption, { name: 'nodeId' })
                                }
                                options={nodes.map((node) => ({
                                    value: node._id,
                                    label: node.nodeNumber
                                }))}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                isClearable={true}
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                                Status
                            </label>
                            <Select
                                name="status"
                                id="status"
                                value={{ value: formData.status, label: formData.status }}
                                onChange={(selectedOption) =>
                                    handleSelectChange(selectedOption, { name: 'status' })
                                }
                                options={[
                                    { value: 'Active', label: 'Active' },
                                    { value: 'Inactive', label: 'Inactive' },
                                    { value: 'Maintenance', label: 'Maintenance' },
                                    { value: 'Not Assigned', label: 'Not Assigned' }
                                ]}
                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            />
                        </div>
                    </>
                )}
                <div className="flex items-center justify-between pt-4">
                    <button type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                        {modalMode === 'create' ? 'Create' : 'Update'}
                    </button>
                    <button
                        type="button"
                        className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                        onClick={handleCloseModal}
                    >
                        Cancel
                    </button>
                </div>
            </form>
        </Modal>
    );
};

export default SetTopBoxForm;


