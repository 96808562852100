// client/src/pages/SetTopBox/SetTopBoxTable.js

import React, { useState } from 'react';
import Table from '../../components/Table';
import QRCodeComponent from '../../components/QRCodeComponent';
import { saveAs } from 'file-saver';
import QRCode from 'qrcode';

const SetTopBoxTable = ({ setTopBoxes, fetchCustomerDetails, handleOpenModal }) => {
    const [expandedRows, setExpandedRows] = useState({});

    if (!Array.isArray(setTopBoxes)) {
        return null;  // Or some fallback UI
    }

    const toggleQRCode = (boxId) => {
        setExpandedRows(prev => ({
            ...prev,
            [boxId]: !prev[boxId]
        }));
    };

    const downloadQRCode = async (box) => {
        try {
            // Generate QR code as a data URL
            const qrCodeDataUrl = await QRCode.toDataURL(box.boxNumber, {
                width: 512,
                margin: 1,
                color: {
                    dark: '#000000',
                    light: '#FFFFFF'
                }
            });

            // Create a new canvas
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');

            // Set canvas size (QR code + space for text)
            const qrCodeSize = 512;
            const textHeight = 100;
            canvas.width = qrCodeSize;
            canvas.height = qrCodeSize + textHeight;

            // Load QR code image
            const qrImage = new Image();
            qrImage.src = qrCodeDataUrl;

            await new Promise((resolve) => {
                qrImage.onload = () => {
                    // Draw white background
                    ctx.fillStyle = '#FFFFFF';
                    ctx.fillRect(0, 0, canvas.width, canvas.height);

                    // Draw QR code
                    ctx.drawImage(qrImage, 0, 0);

                    // Draw text
                    ctx.fillStyle = '#000000';
                    ctx.font = 'bold 30px Arial';
                    ctx.textAlign = 'center';
                    ctx.fillText(box.boxNumber, canvas.width / 2, qrCodeSize + 40);

                    if (box.setTopBoxProvider) {
                        ctx.font = '20px Arial';
                        ctx.fillText(box.setTopBoxProvider, canvas.width / 2, qrCodeSize + 80);
                    }

                    resolve();
                };
            });

            // Convert canvas to blob and trigger download
            canvas.toBlob((blob) => {
                saveAs(blob, `QRCode_${box.boxNumber}.png`);
            });
        } catch (err) {
            console.error('Error generating QR code for download:', err);
        }
    };

    return (
        <Table
            headers={[
                { label: 'Box Number', key: 'boxNumber' },
                { label: 'QR Code', key: 'qrCode' },
                { label: 'Provider', key: 'setTopBoxProvider' },
                { label: 'Optional ID', key: 'optionalId' },
                { label: 'Customer', key: 'customer' },
                { label: 'Status', key: 'status' },
                { label: 'Actions', key: 'actions' }
            ]}
            data={setTopBoxes.map((box) => {
                const latestAssignment = box.assignmentHistory?.[box.assignmentHistory.length - 1] || null;

                return {
                    ...box,
                    qrCode: (
                        <div className="flex flex-col items-center">
                            <button
                                onClick={() => toggleQRCode(box._id)}
                                className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-semibold py-1 px-2 rounded text-sm mb-2"
                            >
                                {expandedRows[box._id] ? 'Hide QR' : 'Show QR'}
                            </button>
                            {expandedRows[box._id] && (
                                <div className="flex flex-col items-center">
                                    <QRCodeComponent value={box.boxNumber} size={100} />
                                    <button
                                        onClick={() => downloadQRCode(box)}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded text-sm mt-2"
                                    >
                                        Download QR
                                    </button>
                                </div>
                            )}
                        </div>
                    ),
                    customer: latestAssignment?.customer ? (
                        <span
                            className="text-blue-500 cursor-pointer"
                            onClick={(e) => {
                                e.stopPropagation();
                                fetchCustomerDetails(latestAssignment.customer._id);
                            }}
                        >
                            {`${latestAssignment.customer.customerName} (${latestAssignment.customer.customerId})`}
                        </span>
                    ) : 'Not Assigned',
                    actions: (
                        <div className="flex justify-center space-x-2">
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    handleOpenModal('edit', box);
                                }}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105 text-sm"
                            >
                                Edit
                            </button>
                            {box.status === 'Not Assigned' ? (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenModal('assign', box);
                                    }}
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105 text-sm"
                                >
                                    Assign
                                </button>
                            ) : (
                                <button
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        handleOpenModal('remove', box);
                                    }}
                                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105 text-sm"
                                >
                                    Remove
                                </button>
                            )}
                        </div>
                    )
                };
            })}
            onCellClick={(row, key) => {
                if (key !== 'actions' && key !== 'customer' && key !== 'qrCode') {
                    handleOpenModal('edit', row);
                }
            }}
        />
    );
};

export default SetTopBoxTable;