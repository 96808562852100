// client/src/components/RoleManagement.js

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../components/ui/button';
import Table from '../components/Table';
import Modal from '../components/Modal';
import AuthContext from '../context/AuthContext';

const RoleManagement = () => {
    const { authAxios } = useContext(AuthContext);
    const [roles, setRoles] = useState([]);
    const [newRole, setNewRole] = useState('');
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        fetchRoles();
    }, []);

    const fetchRoles = async () => {
        try {
            const response = await authAxios.get('/api/roles');
            setRoles(response.data);
        } catch (error) {
            toast.error('Failed to fetch roles');
        }
    };

    const handleAddRole = async () => {
        try {
            const response = await authAxios.post('/api/roles', { name: newRole });
            setRoles([...roles, response.data]);
            toast.success('Role added successfully!');
            setNewRole('');
            setShowModal(false);
        } catch (error) {
            toast.error('Failed to add role');
        }
    };

    const handleDeleteRole = async (roleId) => {
        try {
            await authAxios.delete(`/api/roles/${roleId}`);
            setRoles(roles.filter(role => role._id !== roleId));
            toast.success('Role deleted successfully!');
        } catch (error) {
            toast.error('Failed to delete role');
        }
    };

    const roleHeaders = [
        { label: 'Role Name', key: 'name' },
        { label: 'Actions', key: 'actions' },
    ];

    const roleData = roles.map((role) => ({
        ...role,
        actions: (
            <Button variant="danger" onClick={() => handleDeleteRole(role._id)}>Delete</Button>
        ),
    }));

    return (
        <div className="space-y-6">
            <h2 className="text-xl font-bold">Role Management</h2>

            <Button onClick={() => setShowModal(true)}>Add New Role</Button>

            <Table
                headers={roleHeaders}
                data={roleData}
                onCellClick={() => { }}
            />

            <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} contentLabel="Add Role">
                <div className="space-y-4">
                    <input
                        type="text"
                        value={newRole}
                        onChange={(e) => setNewRole(e.target.value)}
                        placeholder="Role Name"
                        className="border rounded px-3 py-2 w-full"
                    />
                    <div className="flex justify-end">
                        <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        <Button className="ml-2" onClick={handleAddRole}>Save</Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default RoleManagement;
