// client/src/components/pages/SettingsPage.js

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import AuthContext from '../context/AuthContext';
import RoleManagement from '../components/RoleManagement';
import UserManagement from '../components/UserManagement';
import SystemSettings from '../components/SystemSettings';
import { Button } from '../components/ui/button';
import BillingSettings from "../components/BillingSettings";

const tabs = [
    { name: 'System Settings', component: SystemSettings },
    { name: 'User Management', component: UserManagement },
    { name: 'Role Management', component: RoleManagement },
    { name: 'Billing', component: BillingSettings },
];

const SettingsPage = () => {
    const { authAxios } = useContext(AuthContext);
    const [activeTab, setActiveTab] = useState('System Settings');
    const [settings, setSettings] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {
            try {
                const response = await authAxios.get('/api/settings');
                setSettings(response.data || {});
            } catch (error) {
                toast.error('Failed to fetch settings');
            }
        };
        fetchSettings();
    }, [authAxios]);

    const ActiveComponent = tabs.find(tab => tab.name === activeTab).component;

    return (
        <div className="space-y-6 p-6">
            <h1 className="text-2xl font-bold">Settings</h1>

            {/* Tab Navigation */}
            <div className="flex border-b border-gray-200">
                {tabs.map((tab) => (
                    <button
                        key={tab.name}
                        onClick={() => setActiveTab(tab.name)}
                        className={`py-2 px-4 text-sm font-medium ${activeTab === tab.name ? 'border-b-2 border-blue-500 text-blue-500' : 'text-gray-500 hover:text-blue-500'}`}
                    >
                        {tab.name}
                    </button>
                ))}
            </div>

            {/* Tab Content */}
            <div className="mt-4">
                <ActiveComponent />
            </div>
        </div>
    );
};

export default SettingsPage;
