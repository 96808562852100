// client/src/pages/NodesPage.js

import React, { useState, useEffect, useContext } from 'react';
import MapComponent from '../components/MapComponent';
import NodeList from './NodeList';
import AuthContext from '../context/AuthContext';
import ControlPanel from './ControlPanel';
import Modal from '../components/Modal';
import NodeForm from './NodeForm';
import NodeDetails from './NodeDetails';
import Loader from '../components/Loader';

function NodesPage() {
    const { authAxios } = useContext(AuthContext);
    const [showNodes, setShowNodes] = useState(true);
    const [showCustomers, setShowCustomers] = useState(false);
    const [showConnections, setShowConnections] = useState(true);
    const [lineColor, setLineColor] = useState('#ff7800');
    const [nodes, setNodes] = useState([]);
    const [connections, setConnections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mapCenter, setMapCenter] = useState([11.1271, 78.6569]);

    const [searchQuery, setSearchQuery] = useState('');  // New state for search query

    // State for modals
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
    const [selectedNode, setSelectedNode] = useState(null);
    const [modalMode, setModalMode] = useState('add');

    const fetchNodes = async () => {
        try {
            const nodesResponse = await authAxios.get('/api/nodes');
            setNodes(nodesResponse.data);
            const firstNode = nodesResponse.data[0];
            if (firstNode) {
                setMapCenter([firstNode.latitude, firstNode.longitude]);
            }
            setLoading(false);
        } catch (err) {
            console.error('Error fetching nodes:', err);
            setError('Failed to fetch nodes');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchNodes();
    }, []);

    const handleAddNode = () => {
        setModalMode('add');
        setSelectedNode(null);
        setIsModalOpen(true);
    };

    const handleUpdateNode = (node) => {
        setModalMode('update');
        setSelectedNode(node);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedNode(null);
    };

    const handleSubmitNode = () => {
        if (modalMode === 'add') {
            setNodes([...nodes, selectedNode]);
        } else {
            setNodes(nodes.map(node => node._id === selectedNode._id ? selectedNode : node));
        }
        handleCloseModal();
    };

    const toggleNodeDetails = (nodeId) => {
        setSelectedNode(nodeId);
        setIsDetailsModalOpen(true);
    };

    const handleCloseDetailsModal = () => {
        setIsDetailsModalOpen(false);
        setSelectedNode(null);
    };

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    if (error) return <div className="p-4 text-red-500">{error}</div>;

    return (
        <div className="h-full w-full flex">
            {/* NodeList component */}
            <NodeList
                nodes={nodes}
                searchQuery={searchQuery}  // Pass searchQuery
                setSearchQuery={setSearchQuery}  // Pass setSearchQuery
                toggleNodeDetails={toggleNodeDetails}
            />

            {/* Map Component taking the remaining space */}
            <div className="flex-grow relative">
                <div className="absolute inset-0 z-0">
                    <MapComponent
                        customers={[]}
                        nodes={nodes}
                        showNodes={showNodes}
                        showCustomers={showCustomers}
                        showConnections={showConnections}
                        lineColor={lineColor}
                        mapCenter={mapCenter}
                        initialZoom={13}
                        zoomInOnLoad={true}
                    />
                </div>

                {/* Control Panel positioned above the map */}
                <div className="absolute top-4 right-4 z-50">
                    <ControlPanel
                        showNodes={showNodes}
                        setShowNodes={setShowNodes}
                        showCustomers={showCustomers}
                        setShowCustomers={setShowCustomers}
                        showConnections={showConnections}
                        setShowConnections={setShowConnections}
                        lineColor={lineColor}
                        setLineColor={setLineColor}
                        onAddNode={handleAddNode}
                    />
                </div>
            </div>

            <Modal
                isOpen={isModalOpen}
                onRequestClose={handleCloseModal}
                contentLabel={modalMode === 'update' ? 'Update Node' : 'Add Node'}
                onSubmit={handleSubmitNode}
            >
                <NodeForm
                    isUpdate={modalMode === 'update'}
                    nodeData={selectedNode}
                    onSubmit={handleSubmitNode}
                    onClose={handleCloseModal}
                />
            </Modal>

            {/* Node Details Modal */}
            <Modal
                isOpen={isDetailsModalOpen}
                onRequestClose={handleCloseDetailsModal}
                contentLabel="Node Details"
            >
                <NodeDetails nodeId={selectedNode} onClose={handleCloseDetailsModal} />
            </Modal>
        </div>
    );
}

export default NodesPage;
