// client/src/components/LineChartComponent.js

import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Area, linearGradient } from 'recharts';

const LineChartComponent = ({
                                data, // The data to be displayed on the chart
                                xKey, // The key for the X-Axis (typically date or time)
                                lines, // Array of lines to be drawn, each object contains the dataKey and stroke color
                                title, // Optional title for the chart
                                height = 400, // Height of the chart
                                width = '100%', // Width of the chart, defaulting to 100% for responsiveness
                                yAxisLabel, // Optional label for the Y-Axis
                                xAxisLabel // Optional label for the X-Axis
                            }) => {
    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            {title && <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>}
            <ResponsiveContainer width={width} height={height}>
                <LineChart data={data}>
                    <defs>
                        {lines.map((line, index) => (
                            <linearGradient id={`colorUv${index}`} key={index} x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor={line.stroke} stopOpacity={0.3} />
                                <stop offset="100%" stopColor={line.stroke} stopOpacity={0} />
                            </linearGradient>
                        ))}
                    </defs>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey={xKey} label={xAxisLabel ? { value: xAxisLabel, position: 'insideBottomRight', offset: -5 } : null} />
                    <YAxis label={yAxisLabel ? { value: yAxisLabel, angle: -90, position: 'insideLeft' } : null} />
                    <Tooltip />
                    <Legend />
                    {lines.map((line, index) => (
                        <React.Fragment key={index}>
                            <Area
                                type="monotone"
                                dataKey={line.dataKey}
                                stroke={line.stroke}
                                strokeWidth={3}  // Thicker lines
                                fill={`url(#colorUv${index})`}
                                fillOpacity={1}
                            />
                            <Line
                                type="monotone"
                                dataKey={line.dataKey}
                                stroke={line.stroke}
                                strokeWidth={2}  // Thicker lines
                                dot={{ fill: line.stroke, stroke: '#fff', strokeWidth: 2, r: 4 }}  // Custom dots
                                activeDot={{ r: 8 }}
                            />
                        </React.Fragment>
                    ))}
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LineChartComponent;

