// client/src/pages/Layout.js

import React, { useState, useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { Menu, X, Search } from 'lucide-react';
import AuthContext from '../context/AuthContext';

const Layout = ({ children }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [isDropdownVisible, setIsDropdownVisible] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [customerStats, setCustomerStats] = useState({
        total: 0,
        active: 0,
        deactive: 0,
        inactive: 0,
    });
    const [nodeCount, setNodeCount] = useState(0);
    const { user, logout, authAxios } = useContext(AuthContext);
    const suggestions = ["Customer 1", "Customer 2", "Customer 3"];

    useEffect(() => {
        // Function to fetch customer data
        const fetchCustomerData = async () => {
            try {
                const response = await authAxios.get('/api/customers/stats');
                setCustomerStats(response.data);
            } catch (error) {
                console.error('Failed to fetch customer data', error);
            }
        };

        // Function to fetch node count
        const fetchNodeCount = async () => {
            try {
                const response = await authAxios.get('/api/nodes/count');
                setNodeCount(response.data.count);
            } catch (error) {
                console.error('Failed to fetch node count', error);
            }
        };

        // Fetch data initially
        fetchCustomerData();
        fetchNodeCount();

        // Set interval to update data every minute (or any desired interval)
        const intervalId = setInterval(() => {
            fetchCustomerData();
            fetchNodeCount();
        }, 60000); // Update every 60 seconds

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [authAxios]);

    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setIsDropdownVisible(e.target.value.length > 0);
    };

    const toggleMobileMenu = () => {
        setIsMobileMenuOpen(!isMobileMenuOpen);
    };

    const closeMobileMenu = () => {
        setIsMobileMenuOpen(false);
    };

    const handleLogout = () => {
        logout();
    };

    return (
        <div className="min-h-screen flex flex-col bg-gray-50">
            {/* Top Navigation */}
            <nav className="bg-white shadow-md py-3 px-4 flex justify-between items-center z-20">
                <div className="flex items-center">
                    <button onClick={toggleMobileMenu} className="mr-2 md:hidden focus:outline-none">
                        {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
                    </button>
                    <img src="/user.png" alt="User" className="w-8 h-8 rounded-full" />
                    <span className="ml-2 text-gray-700 font-semibold text-sm md:text-base">
                        {user ? user.username : 'User Name'}
                    </span>
                </div>
                <div className="hidden md:flex relative mx-4 flex-grow max-w-lg">
                    <input
                        type="text"
                        placeholder="Search..."
                        className="w-full border border-gray-300 rounded-full py-2 px-4 focus:outline-none focus:ring-2 focus:ring-primary"
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                    {isDropdownVisible && (
                        <div className="absolute w-full mt-1 bg-white border border-gray-300 rounded-md shadow-lg z-30">
                            {suggestions.length > 0 ? (
                                suggestions.map((suggestion, index) => (
                                    <div key={index} className="p-2 hover:bg-gray-100 cursor-pointer">
                                        {suggestion}
                                    </div>
                                ))
                            ) : (
                                <div className="p-2 text-gray-600">No results found</div>
                            )}
                        </div>
                    )}
                </div>
                <button className="md:hidden focus:outline-none">
                    <Search size={24} />
                </button>
            </nav>

            <div className="flex flex-1 overflow-hidden">
                {/* Side Navigation - Hidden on mobile, visible on larger screens */}
                <aside className={`${isMobileMenuOpen ? 'block' : 'hidden'} md:block w-full md:w-64 bg-gray-800 text-white shadow-lg p-6 flex flex-col justify-between z-20 fixed md:relative h-full overflow-y-auto`}>
                    <nav className="flex flex-col space-y-4">
                        <NavLink
                            to="/"
                            exact
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Home
                        </NavLink>
                        <NavLink
                            to="/customers"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Customers
                        </NavLink>
                        <NavLink
                            to="/payments"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Payments
                        </NavLink>
                        <NavLink
                            to="/settopboxes"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            STB
                        </NavLink>
                        <NavLink
                            to="/nodes"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Nodes
                        </NavLink>
                        <NavLink
                            to="/complaints"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Complaints
                        </NavLink>
                        <NavLink
                            to="/subscription-plans"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Subscription Plans
                        </NavLink>
                        <NavLink
                            to="/stats"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Stats
                        </NavLink>
                        <NavLink
                            to="/settings"
                            className="text-gray-300 hover:bg-gray-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300"
                            activeClassName="bg-gray-900 text-white"
                            onClick={closeMobileMenu}
                        >
                            Settings
                        </NavLink>
                        <button onClick={handleLogout} className="text-gray-300 hover:bg-red-700 hover:text-white rounded-md px-4 py-2 transition-colors duration-300">
                            Logout
                        </button>
                    </nav>
                    <div className="bg-gray-900 p-4 rounded-lg mt-8 text-center">
                        <div className="flex justify-between items-center mb-2">
                            <h2 className="text-lg font-bold text-gray-300">Customers</h2>
                            <span className="text-2xl font-bold">{customerStats.total}</span>
                        </div>
                        <div className="grid grid-cols-2 gap-2 text-sm">
                            <div className="bg-green-500 p-2 rounded text-center">
                                <div>Active</div>
                                <div className="font-bold text-white">{customerStats.active}</div>
                            </div>
                            <div className="bg-red-500 p-2 rounded text-center">
                                <div>Deactive</div>
                                <div className="font-bold text-white">{customerStats.deactive}</div>
                            </div>
                        </div>
                        <div className="mt-4 flex justify-between items-center">
                            <h2 className="text-lg font-bold text-gray-300">Nodes</h2>
                            <span className="text-2xl font-bold">{nodeCount}</span>
                        </div>
                    </div>
                </aside>

                {/* Main Content Area */}
                <main className="flex-1 overflow-hidden relative z-10">
                    <div className="h-full overflow-y-auto p-4 md:p-6">
                        {children}
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Layout;
