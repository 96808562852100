import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../context/AuthContext';
import { Alert, AlertDescription } from '../components/ui/alert';
import { Card, CardHeader, CardContent, CardFooter } from '../components/ui/card';
import { Button } from '../components/ui/button';

const NodeDetails = ({ nodeId, onClose }) => {
    const [node, setNode] = useState(null);
    const [error, setError] = useState(null);
    const [selectedBox, setSelectedBox] = useState(null);
    const [customerDetails, setCustomerDetails] = useState(null);
    const { authAxios } = useContext(AuthContext);

    useEffect(() => {
        const fetchNodeDetails = async () => {
            if (nodeId) {
                try {
                    const response = await authAxios.get(`/api/nodes/${nodeId}`);
                    setNode(response.data);
                    setError(null);
                } catch (error) {
                    console.error('Error fetching node details:', error);
                    setError('Failed to fetch node details. Please try again.');
                }
            }
        };

        fetchNodeDetails();
    }, [nodeId, authAxios]);

    const fetchCustomerDetails = async (customerId) => {
        try {
            const response = await authAxios.get(`/api/customers/${customerId}`);
            setCustomerDetails(response.data);
        } catch (error) {
            console.error('Error fetching customer details:', error);
            setCustomerDetails(null);
        }
    };

    const handleBoxSelect = async (box) => {
        setSelectedBox(box);
        if (box.latestAssignment && box.latestAssignment.customer) {
            await fetchCustomerDetails(box.latestAssignment.customer);
        } else {
            setCustomerDetails(null);
        }
    };

    if (error) {
        return (
            <Alert variant="destructive">
                <AlertDescription>{error}</AlertDescription>
            </Alert>
        );
    }

    if (!node) return <div>Loading...</div>;

    return (
        <Card className="w-full max-w-2xl mx-auto">
            <CardHeader>
                <h3 className="text-2xl font-bold">Node Details: {node.nodeNumber}</h3>
            </CardHeader>
            <CardContent>
                <div className="grid grid-cols-2 gap-4">
                    <div>
                        <p><strong>Latitude:</strong> {node.latitude}</p>
                        <p><strong>Longitude:</strong> {node.longitude}</p>
                    </div>
                    <div>
                        <p><strong>Status:</strong> {node.status}</p>
                        <p><strong>Hosted By:</strong> {node.hostedBy || 'Not specified'}</p>
                    </div>
                </div>

                <h4 className="text-lg font-semibold mt-6 mb-2">Service History</h4>
                {node.serviceHistory && node.serviceHistory.length > 0 ? (
                    <ul className="list-disc list-inside">
                        {node.serviceHistory.map((service, index) => (
                            <li key={index}>
                                {new Date(service.date).toLocaleDateString()} - {service.remarks}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No service history available.</p>
                )}

                <h4 className="text-lg font-semibold mt-6 mb-2">Connected Set-Top Boxes</h4>
                {node.boxes && node.boxes.length > 0 ? (
                    <ul className="list-disc list-inside">
                        {node.boxes.map((box) => (
                            <li key={box._id} className="cursor-pointer hover:text-blue-600" onClick={() => handleBoxSelect(box)}>
                                {box.boxNumber}
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No connected set-top boxes.</p>
                )}

                {selectedBox && (
                    <div className="mt-4 p-4 border rounded">
                        <h5 className="font-semibold">Selected Box Details: {selectedBox.boxNumber}</h5>
                        <p><strong>Status:</strong> {selectedBox.status}</p>
                        <p><strong>Provider:</strong> {selectedBox.setTopBoxProvider}</p>
                        {selectedBox.latestAssignment && (
                            <>
                                <p><strong>Assigned To:</strong> {customerDetails ? customerDetails.customerName : 'Loading...'}</p>
                                <p><strong>Address:</strong> {customerDetails ? customerDetails.addressLine1 : 'Loading...'}</p>
                                <p><strong>Phone:</strong> {customerDetails ? customerDetails.phoneNumber : 'Loading...'}</p>
                                <p><strong>Assigned Date:</strong> {new Date(selectedBox.latestAssignment.assignedDate).toLocaleDateString()}</p>
                                <p><strong>Assignment Status:</strong> {selectedBox.latestAssignment.status}</p>
                            </>
                        )}
                    </div>
                )}
            </CardContent>
            <CardFooter className="flex justify-end">
                <Button onClick={onClose} variant="secondary">
                    Close
                </Button>
            </CardFooter>
        </Card>
    );
};

export default NodeDetails;