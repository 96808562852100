// client/src/components/PieChartStats.js

import React, { useState } from 'react';
import { PieChart, Pie, Tooltip, Cell, Legend } from 'recharts';

const COLORS = ['#8884d8', '#82ca9d', '#ffc658', '#ff8042', '#8dd1e1'];

const PieChartStats = ({ title, data }) => {
    const [activeIndex, setActiveIndex] = useState(-1);
    const [hiddenIndexes, setHiddenIndexes] = useState([]);

    const onMouseEnter = (_, index) => {
        setActiveIndex(index);
    };

    const onMouseLeave = () => {
        setActiveIndex(-1);
    };

    const toggleLegendItem = (index) => {
        setHiddenIndexes((prev) =>
            prev.includes(index)
                ? prev.filter((i) => i !== index)
                : [...prev, index]
        );
    };

    const filteredData = data.filter((_, index) => !hiddenIndexes.includes(index));
    const total = filteredData.reduce((sum, entry) => sum + entry.value, 0);

    return (
        <div className="bg-white rounded-lg shadow-md p-6">
            <h2 className="text-xl font-semibold text-gray-800 mb-4">{title}</h2>
            <PieChart width={400} height={300}>
                <defs>
                    {filteredData.map((_, index) => (
                        <radialGradient key={index} id={`gradient-${index}`}>
                            <stop offset="30%" stopColor={COLORS[index % COLORS.length]} stopOpacity={0.6} />
                            <stop offset="100%" stopColor={COLORS[index % COLORS.length]} stopOpacity={1} />
                        </radialGradient>
                    ))}
                </defs>
                <Pie
                    data={filteredData}
                    cx={200}
                    cy={150}
                    innerRadius={60}
                    outerRadius={120}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                    label={({ value }) => `${((value / total) * 100).toFixed(2)}%`}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    stroke="#ffffff"  // Add a white stroke to the outer side of the pie
                    strokeWidth={3}   // Increase stroke width for better visibility
                >
                    {filteredData.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={`url(#gradient-${index})`}
                            opacity={activeIndex === index ? 1 : 0.8}
                            stroke={COLORS[index % COLORS.length]}
                            strokeWidth={activeIndex === index ? 4 : 2} // Highlight the stroke on hover
                        />
                    ))}
                </Pie>
                <Tooltip />
                <Legend
                    formatter={(value, entry, index) => {
                        const isHidden = hiddenIndexes.includes(index);
                        return (
                            <span
                                onClick={() => toggleLegendItem(index)}
                                style={{
                                    textDecoration: isHidden ? 'line-through' : 'none',
                                    color: isHidden ? '#999' : '#000',
                                    cursor: 'pointer',
                                }}
                            >
                                {value} ({data[index].value})
                            </span>
                        );
                    }}
                />
            </PieChart>
        </div>
    );
};

export default PieChartStats;


