// client/src/pages/StatsPage.js

import React, { useState, useEffect, useContext } from 'react';
import Table from '../components/Table';
import PieChartStats from '../components/PieChartStats';
import NumericStats from '../components/NumericStats';
import AuthContext from '../context/AuthContext';
import Loader from '../components/Loader';
import LineChartComponent from '../components/LineChartComponent'; // Import the reusable LineChartComponent

function StatsPage() {
    const { authAxios } = useContext(AuthContext);
    const [customerStats, setCustomerStats] = useState({});
    const [subscriptionData, setSubscriptionData] = useState([]);
    const [customerGrowthData, setCustomerGrowthData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchStats = async () => {
            setLoading(true);
            try {
                const [customerStatsRes, subscriptionRes, customerGrowthRes] = await Promise.all([
                    authAxios.get('/api/customers/stats'),
                    authAxios.get('/api/subscriptionplans/stats'),
                    authAxios.get('/api/settopboxes/customer-growth')
                ]);

                setCustomerStats(customerStatsRes.data);
                setSubscriptionData(subscriptionRes.data);
                setCustomerGrowthData(customerGrowthRes.data);
            } catch (err) {
                console.error('Error fetching stats:', err);
            } finally {
                setLoading(false);
            }
        };

        fetchStats();
    }, [authAxios]);

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (
            <div className="p-4 md:p-8 space-y-8 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg shadow-lg">
                <h1 className="text-3xl font-extrabold text-gray-800 mb-6">Business Stats Overview</h1>

                {/* Overall Customer Stats */}
                <NumericStats
                    data={[
                        { label: 'Total Customers', value: customerStats.total, color: 'bg-gradient-to-r from-green-400 to-green-600' },
                        { label: 'Active Customers', value: customerStats.active, color: 'bg-gradient-to-r from-blue-400 to-blue-600' },
                        { label: 'Deactive Customers', value: customerStats.deactive, color: 'bg-gradient-to-r from-yellow-400 to-yellow-600' },
                        { label: 'Inactive Customers', value: customerStats.inactive, color: 'bg-gradient-to-r from-red-400 to-red-600' },
                    ]}
                />

                <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                    {/* Customer Status Distribution */}
                    <PieChartStats
                        title="Customer Status Distribution"
                        data={[
                            { name: 'Active', value: customerStats.active },
                            { name: 'Deactive', value: customerStats.deactive },
                            { name: 'Inactive', value: customerStats.inactive },
                        ]}
                    />

                    {/* Subscription Plan Analysis */}
                    <PieChartStats
                        title="Subscription Plan Distribution"
                        data={subscriptionData.map(plan => ({
                            name: plan.planName,
                            value: plan.customerCount
                        }))}
                    />
                </div>

                {/* Customer Growth Trend using the reusable LineChartComponent */}
                <LineChartComponent
                    title="Customer Growth Over Time"
                    data={customerGrowthData}
                    xKey="date"
                    lines={[
                        { dataKey: 'activeCustomers', stroke: '#8884d8' },
                    ]}
                    xAxisLabel="Date"
                    yAxisLabel="Active Customers"
                />

                {/* Subscription Plan Table */}
                <div className="bg-white rounded-lg shadow-md p-6">
                    <h2 className="text-xl font-semibold text-gray-800 mb-4">Subscription Plan Analysis</h2>
                    <Table
                        headers={[
                            { label: 'Plan Name', key: 'planName' },
                            { label: 'Customer Count', key: 'customerCount' },
                            { label: 'Total Revenue', key: 'revenue' }
                        ]}
                        data={subscriptionData}
                        highlightOnHover
                    />
                </div>

                {/* Further sections can be added similarly */}
            </div>
    );
}

export default StatsPage;





