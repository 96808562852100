// client/src/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { AuthProvider } from './context/AuthContext';
import PrivateRoute from './components/PrivateRoute';
import Layout from './pages/Layout';
import HomePage from './pages/HomePage';
import CustomerPage from './pages/Customer/CustomerPage';
import NodesPage from './pages/NodesPage';
import CustomerRegistration from './pages/Customer/CustomerRegistration';
import CustomerUpdate from './pages/Customer/CustomerUpdate';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import PaymentsPage from './pages/Payments/PaymentsPage';
import SetTopBoxPage from './pages/SetTopBox/SetTopBoxPage';
import SubscriptionPlansPage from './pages/SubscriptionPlansPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import StatsPage from "./pages/StatsPage";
import SettingsPage from "./pages/SettingsPage";

function App() {
    return (
        <ChakraProvider>
            <AuthProvider>
                <Router>
                    <ToastContainer />
                    <Routes>
                        {/* Public Routes */}
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />

                        {/* Protected Routes */}
                        <Route
                            path="/"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <HomePage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/customers"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <CustomerPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/nodes"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <NodesPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/register-customer"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <CustomerRegistration />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/update-customer/:id"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <CustomerUpdate />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/payments"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <PaymentsPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/settopboxes"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <SetTopBoxPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/subscription-plans"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <SubscriptionPlansPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/stats"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <StatsPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                        <Route
                            path="/settings"
                            element={
                                <PrivateRoute>
                                    <Layout>
                                        <SettingsPage />
                                    </Layout>
                                </PrivateRoute>
                            }
                        />
                    </Routes>
                </Router>
            </AuthProvider>
        </ChakraProvider>
    );
}

export default App;
