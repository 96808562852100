// client/src/components/HorizontalTimeline.js

import React from 'react';
import './HorizontalTimeline.css';

const generateTimeline = (activities) => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    let startDate, endDate;

    // Calculate the range of dates
    activities.forEach(activity => {
        const activityDate = new Date(activity.date);
        if (!startDate || activityDate < startDate) startDate = activityDate;
        if (!endDate || activityDate > endDate) endDate = activityDate;
    });

    // Generate all months/years between startDate and endDate
    const timeline = [];
    let currentDate = startDate ? new Date(startDate.getFullYear(), startDate.getMonth(), 1) : null;
    endDate = endDate ? new Date(endDate.getFullYear(), endDate.getMonth(), 1) : null;

    if (currentDate && endDate) {
        while (currentDate <= endDate) {
            timeline.push({
                year: currentDate.getFullYear(),
                month: months[currentDate.getMonth()],
                setTopBoxEvents: [],
                billEvents: [],
                active: false,  // This will be determined below
            });
            currentDate.setMonth(currentDate.getMonth() + 1);
        }
    }

    // Map activities to the timeline and determine the active status
    let lastStatus = "Inactive"; // Assume it starts as Inactive
    timeline.forEach((slot, index) => {
        slot.active = lastStatus === "Active";
        activities.forEach((activity) => {
            const activityDate = new Date(activity.date);
            if (slot.year === activityDate.getFullYear() && slot.month === months[activityDate.getMonth()]) {
                if (activity.type === "SetTopBox") {
                    slot.setTopBoxEvents.push(activity);
                    if (activity.details.status === "Active") {
                        slot.active = true;
                        lastStatus = "Active";
                    } else if (activity.details.status === "Inactive" || activity.details.status === "Deactive") {
                        slot.active = false;
                        lastStatus = "Inactive";
                    }
                } else if (activity.type === "Bill") {
                    slot.billEvents.push(activity);
                }
            }
        });

        // If this is the last slot and the status is active, ensure the line continues
        if (index === timeline.length - 1 && lastStatus === "Active") {
            slot.active = true;
        }
    });

    return timeline;
};

const HorizontalTimeline = ({ activities }) => {
    const timeline = generateTimeline(activities);

    const getBillColor = (status) => {
        return status === "Pending" || status === "Unpaid" ? "red" : "green";
    };

    const getSetTopBoxColor = (status) => {
        return status === "Active" ? "blue" : "orange";
    };

    return (
        <div className="horizontal-timeline-container">
            <div className="legend">
                <span className="legend-item"><span className="legend-point" style={{ backgroundColor: "red" }}></span> Pending/Unpaid Bill</span>
                <span className="legend-item"><span className="legend-point" style={{ backgroundColor: "green" }}></span> Paid Bill</span>
                <span className="legend-item"><span className="legend-point" style={{ backgroundColor: "blue" }}></span> Active SetTopBox</span>
                <span className="legend-item"><span className="legend-point" style={{ backgroundColor: "orange" }}></span> Inactive/Deactivated SetTopBox</span>
                <span className="legend-item"><span className="legend-line" style={{ backgroundColor: "green" }}></span> Active Connection</span>
                <span className="legend-item"><span className="legend-line" style={{ backgroundColor: "red" }}></span> Inactive Connection</span>
            </div>
            <div className="horizontal-timeline">
                {timeline.map((slot, index) => (
                    <div key={index} className="timeline-slot">
                        <div className="timeline-header">
                            <div className="timeline-month">{slot.month}</div>
                            <div className="timeline-year">{slot.year}</div>
                        </div>
                        <div className="timeline-line-container">
                            {index < timeline.length - 1 && (
                                <div className={`timeline-line ${slot.active ? 'active' : 'inactive'}`}></div>
                            )}
                        </div>
                        <div className="timeline-events-row bill-row">
                            {slot.billEvents.length > 0 ? (
                                slot.billEvents.map((activity, idx) => (
                                    <div
                                        key={idx}
                                        className={`timeline-event bill`}
                                        title={`${activity.type.toUpperCase()}: ${activity.details.status || 'N/A'}`}
                                    >
                                        <div className="timeline-point" style={{ backgroundColor: getBillColor(activity.details.status) }}></div>
                                    </div>
                                ))
                            ) : (
                                <div className="timeline-event empty"></div>
                            )}
                        </div>
                        <div className="timeline-events-row settopbox-row">
                            {slot.setTopBoxEvents.length > 0 ? (
                                slot.setTopBoxEvents.map((activity, idx) => (
                                    <div
                                        key={idx}
                                        className={`timeline-event settopbox`}
                                        title={`${activity.type.toUpperCase()}: ${activity.details.status || 'N/A'}`}
                                    >
                                        <div className="timeline-point" style={{ backgroundColor: getSetTopBoxColor(activity.details.status) }}></div>
                                    </div>
                                ))
                            ) : (
                                <div className="timeline-event empty"></div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default HorizontalTimeline;








