// client/src/components/Billing/BillingPaymentDetailsModal.js

import React, { useEffect, useState, useContext } from 'react';
import Modal from '../Modal';
import AuthContext from '../../context/AuthContext';
import Loader from '../Loader';
import BillingDetailsTable from './BillingDetailsTable';
import PaymentDetailsTable from './PaymentDetailsTable';
import BillingPaymentSummary from './BillingPaymentSummary';
import HorizontalTimeline from '../HorizontalTimeline';
import CustomerActivityLog from '../../pages/Customer/CustomerActivityLog';

const BillingPaymentDetailsModal = ({ customerId, showModal, handleCloseModal }) => {
    const { authAxios } = useContext(AuthContext);
    const [billingDetails, setBillingDetails] = useState([]);
    const [paymentDetails, setPaymentDetails] = useState([]);
    const [activityLogs, setActivityLogs] = useState([]);
    const [loading, setLoading] = useState(true);

    const [totalDue, setTotalDue] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);
    const [outstanding, setOutstanding] = useState(0);

    useEffect(() => {
        if (customerId && showModal) {
            fetchBillingAndPaymentDetails();
            fetchActivityLogs();
        }
    }, [customerId, showModal]);

    const fetchBillingAndPaymentDetails = async () => {
        setLoading(true);
        try {
            const billingRes = await authAxios.get(`/api/customers/${customerId}/bills`);
            const paymentRes = await authAxios.get(`/api/customers/${customerId}/payments`);

            setBillingDetails(billingRes.data);
            setPaymentDetails(paymentRes.data);

            // Calculate totals
            calculateTotals(billingRes.data, paymentRes.data);
        } catch (err) {
            console.error('Error fetching billing or payment details:', err);
        } finally {
            setLoading(false);
        }
    };

    const fetchActivityLogs = async () => {
        try {
            const logsRes = await authAxios.get(`/api/customers/${customerId}/activity-log`);
            setActivityLogs(logsRes.data);
        } catch (err) {
            console.error('Error fetching activity logs:', err);
        }
    };

    const calculateTotals = (billingData, paymentData) => {
        const totalDueAmount = billingData.reduce((acc, bill) => acc + (parseFloat(bill.amountDue) || 0), 0);
        const totalPaidAmount = paymentData.reduce((acc, payment) => acc + (parseFloat(payment.amount) || 0), 0);
        const outstandingAmount = totalDueAmount - totalPaidAmount;

        setTotalDue(totalDueAmount);
        setTotalPaid(totalPaidAmount);
        setOutstanding(outstandingAmount);
    };

    return (
        <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            contentLabel="Billing and Payment Details"
            size="7xl"
            maxHeight="70vh"
        >
            <div className="p-6">
                <h2 className="text-3xl font-bold text-gray-800 mb-6">Billing and Payment Details</h2>
                {loading ? (
                    <Loader />
                ) : (
                    <>
                        <BillingPaymentSummary
                            totalDue={totalDue}
                            totalPaid={totalPaid}
                            outstanding={outstanding}
                        />
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-gray-700 mb-4">Billing Details</h3>
                            <div className="shadow-lg rounded-lg overflow-hidden">
                                <BillingDetailsTable billingDetails={billingDetails} />
                            </div>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-gray-700 mb-4">Payment Details</h3>
                            <div className="shadow-lg rounded-lg overflow-hidden">
                                <PaymentDetailsTable paymentDetails={paymentDetails} />
                            </div>
                        </div>
                        <div className="mt-8">
                            <h3 className="text-xl font-semibold text-gray-700 mb-4">Customer Activity Log</h3>
                            <div className="shadow-lg rounded-lg overflow-hidden">
                                <HorizontalTimeline activities={activityLogs} />
                                <div className="mt-6">
                                    <CustomerActivityLog customerId={customerId} />
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className="mt-6 text-right">
                    <button
                        onClick={handleCloseModal}
                        className="bg-gray-600 hover:bg-gray-800 text-white font-bold py-2 px-6 rounded shadow-lg transition duration-300"
                    >
                        Close
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default BillingPaymentDetailsModal;
