// client/src/pages/NodesList.js

import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';

const NodeList = ({ searchQuery = '', setSearchQuery, toggleNodeDetails }) => {  // Default value added here
    const [nodes, setNodes] = useState([]);
    const { authAxios } = useContext(AuthContext);

    useEffect(() => {
        const fetchNodes = async () => {
            try {
                const response = await authAxios.get('/api/nodes');
                setNodes(response.data);
            } catch (error) {
                console.error('Error fetching nodes:', error);
            }
        };

        fetchNodes();
    }, [authAxios]);

    const getStatusIcon = (status) => {
        if (status === 'Active') {
            return <FontAwesomeIcon icon={faCheckCircle} className="text-green-500" />;
        } else if (status === 'Inactive') {
            return <FontAwesomeIcon icon={faTimesCircle} className="text-red-500" />;
        } else {
            return <FontAwesomeIcon icon={faCircle} className="text-gray-500" />;
        }
    };

    const filteredNodes = nodes.filter((node) =>
        node.nodeNumber.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <div className="p-4 w-1/4 bg-white shadow-md h-full overflow-y-auto">
            <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder="Search nodes..."
                className="mb-4 p-2 w-full border rounded"
            />
            {filteredNodes.map((node) => (
                <div key={node._id} className="mb-2">
                    <button
                        className="text-left w-full p-2 bg-gray-200 hover:bg-gray-300 rounded flex items-center"
                        onClick={() => toggleNodeDetails(node._id)}
                    >
                        {getStatusIcon(node.status)}
                        <span className="ml-2">{node.nodeNumber}</span>
                    </button>
                </div>
            ))}
        </div>
    );
};

export default NodeList;


