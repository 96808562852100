// client/src/components/BulkActions.js

import React, { useContext, useState, useEffect } from 'react';
import { Button } from './ui/button';
import Modal from './Modal';
import AuthContext from '../context/AuthContext';
import { Alert, AlertTitle, AlertDescription } from './ui/alert';
import { toast } from 'react-toastify';

const BulkActions = ({ selectedIds, refreshData, actions, entityType }) => {
    const { authAxios } = useContext(AuthContext);
    const [modalOpen, setModalOpen] = useState(false);
    const [actionType, setActionType] = useState('');
    const [error, setError] = useState(null);
    const [additionalData, setAdditionalData] = useState({});

    useEffect(() => {
        if (actions[actionType]?.fetchAdditionalData) {
            actions[actionType].fetchAdditionalData(authAxios, setAdditionalData);
        }
    }, [actionType, authAxios, actions]);

    const handleBulkAction = async () => {
        try {
            setError(null);
            const action = actions[actionType];
            if (!action) return;

            const payload = {
                [`${entityType}Ids`]: selectedIds,
                ...action.getPayload(additionalData)
            };

            await authAxios.post(action.endpoint, payload);
            toast.success('Bulk action completed successfully');
            refreshData();
            setModalOpen(false);
        } catch (err) {
            setError('Failed to perform bulk action. Please try again.');
            toast.error('Failed to perform bulk action');
            console.error(err);
        }
    };

    const openModal = (action) => {
        setActionType(action);
        setModalOpen(true);
    };

    const renderModalContent = () => {
        const action = actions[actionType];
        if (action?.renderModalContent) {
            return action.renderModalContent(additionalData, setAdditionalData);
        }
        return null;
    };

    return (
        <>
            {error && (
                <Alert variant="destructive">
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                </Alert>
            )}
            <div className="flex space-x-2">
                {Object.entries(actions).map(([key, action]) => (
                    <Button
                        key={key}
                        variant={action.variant || "default"}
                        onClick={() => openModal(key)}
                        disabled={selectedIds.length === 0}
                    >
                        {action.label}
                    </Button>
                ))}
            </div>

            <Modal isOpen={modalOpen} onRequestClose={() => setModalOpen(false)} contentLabel="Confirm Bulk Action">
                <div className="text-center">
                    <h3 className="text-xl font-semibold mb-4">
                        Are you sure you want to {actionType.replace(/([A-Z])/g, ' $1').toLowerCase()} the selected items?
                    </h3>
                    {renderModalContent()}
                    <div className="flex justify-center space-x-4">
                        <Button variant="destructive" onClick={handleBulkAction}>
                            Yes, Confirm
                        </Button>
                        <Button variant="default" onClick={() => setModalOpen(false)}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default BulkActions;


