// client/src/pages/HomePage.js
import React, { useState, useEffect, useContext, useCallback } from 'react';
import ControlPanel from '../components/ControlPanel';
import MapComponent from '../components/MapComponent';
import AuthContext from '../context/AuthContext';
import Loader from '../components/Loader';

function HomePage() {
    const { authAxios, logout } = useContext(AuthContext);
    const [showNodes, setShowNodes] = useState(true);
    const [showCustomers, setShowCustomers] = useState(true);
    const [showConnections, setShowConnections] = useState(true);
    const [lineColor, setLineColor] = useState('#ff7800');
    const [customers, setCustomers] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [mapCenter, setMapCenter] = useState([11.1271, 78.6569]); // Default to Tamil Nadu

    const fetchData = useCallback(async () => {
        try {
            const [customersResponse, nodesResponse] = await Promise.all([
                authAxios.get('/api/customers'),
                authAxios.get('/api/nodes')
            ]);

            setCustomers(customersResponse.data);
            setNodes(nodesResponse.data);

            const firstNode = nodesResponse.data[0];
            const firstCustomer = customersResponse.data[0];

            const latitude = firstNode?.latitude || firstCustomer?.latitude || 11.1271;
            const longitude = firstNode?.longitude || firstCustomer?.longitude || 78.6569;

            setMapCenter([latitude, longitude]);
            setLoading(false);
        } catch (err) {
            console.error('Error fetching data:', err);
            setError('Failed to fetch data');
            setLoading(false);
        }
    }, [authAxios]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    if (error) return <div className="p-4 text-red-500">{error}</div>;

    if (loading) {
        return (
            <div>
                <Loader />
            </div>
        );
    }

    return (

            <div className="h-full w-full relative">
                {/* Map Component taking the full space */}
                <div className="absolute inset-0 z-0">
                    <MapComponent
                        customers={customers}
                        nodes={nodes}
                        showNodes={showNodes}
                        showCustomers={showCustomers}
                        showConnections={showConnections}
                        lineColor={lineColor}
                        mapCenter={mapCenter} // Pass the mapCenter state
                        initialZoom={13} // Customize if needed
                        zoomInOnLoad={true} // Set to false if you want to skip the zoom effect
                    />
                </div>

                {/* Control Panel positioned above the map */}
                <div className="absolute top-4 right-4 z-50">
                    <ControlPanel
                        showNodes={showNodes}
                        setShowNodes={setShowNodes}
                        showCustomers={showCustomers}
                        setShowCustomers={setShowCustomers}
                        showConnections={showConnections}
                        setShowConnections={setShowConnections}
                        lineColor={lineColor}
                        setLineColor={setLineColor}
                        onLogout={logout}
                    />
                </div>
            </div>
    );
}

export default HomePage;
