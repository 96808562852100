// client/src/components/pages/paymentsPage.js

import React, { useState, useMemo, useContext, useEffect, useCallback } from 'react';
import PaymentStats from './PaymentStats';
import Modal from '../../components/Modal';
import Table from '../../components/Table';
import PaymentForm from '../../components/PaymentForm';
import ConfirmPaymentModal from './ConfirmPaymentModal'; // Import the ConfirmPaymentModal
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import AuthContext from '../../context/AuthContext';
import Loader from '../../components/Loader';
import { Button } from "@chakra-ui/react";

const PaymentsPage = () => {
    const { authAxios } = useContext(AuthContext);
    const [showModal, setShowModal] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [selectedCustomerId, setSelectedCustomerId] = useState(null);
    const [selectedDate, setSelectedDate] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filterStatus, setFilterStatus] = useState('All');
    const [sortConfig, setSortConfig] = useState({ key: 'date', direction: 'descending' });
    const [allPayments, setAllPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedPaymentIds, setSelectedPaymentIds] = useState([]);

    // Fetch payments data
    const fetchPayments = useCallback(async () => {
        setLoading(true);
        try {
            const response = await authAxios.get('/api/payments');
            setAllPayments(response.data);
        } catch (error) {
            setError('Failed to fetch payments data');
            toast.error('Failed to fetch payments data');
        } finally {
            setLoading(false);
        }
    }, [authAxios]);

    useEffect(() => {
        fetchPayments();
    }, [fetchPayments]);

    const handleOpenModal = (payment) => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleOpenConfirmModal = (customerId) => {
        setSelectedCustomerId(customerId);
        setShowConfirmModal(true);
    };

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false);
        setSelectedCustomerId(null);
    };

    const handleConfirmPayments = (selectedBills) => {
        console.log("Confirming payments for bills:", selectedBills);
        // Implement logic to confirm the selected payments
    };

    const filteredPayments = useMemo(() => {
        return allPayments.filter(payment => {
            const matchesSearch = payment.boxNumber?.includes(searchTerm) ||
                payment.mode?.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesStatus = filterStatus === 'All' || payment.status === filterStatus;
            const matchesDate = !selectedDate || payment.date?.startsWith(selectedDate);
            return matchesSearch && matchesStatus && matchesDate;
        });
    }, [searchTerm, filterStatus, selectedDate, allPayments]);

    const sortedPayments = useMemo(() => {
        let sortedData = [...filteredPayments];
        if (sortConfig.key) {
            sortedData.sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }
        return sortedData;
    }, [filteredPayments, sortConfig]);

    const requestSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const preparedPayments = useMemo(() => {
        return sortedPayments.map(payment => ({
            ...payment,
            id: payment._id,
        }));
    }, [sortedPayments]);

    const handleClearPayments = useCallback(() => {
        console.log("Clearing payments for:", selectedPaymentIds);
        // Implement the logic to clear payments here
    }, [selectedPaymentIds]);

    const exportToExcel = () => {
        const paidPayments = filteredPayments.filter(payment => payment.status === 'Paid');

        const data = paidPayments.map(payment => ({
            'Customer_Details': `${payment.customerId} - ${payment.boxNumber}`,
            'Receipt_Number': payment.receiptNumber,
            'Remarks': payment.remarks
        }));

        const ws = XLSX.utils.json_to_sheet(data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Paid Customers");

        const filename = `paid_customers_${selectedDate || 'All'}.xlsx`;

        XLSX.writeFile(wb, filename);
        toast.success('Paid customers exported successfully!');
    };

    const chartData = useMemo(() => {
        const data = {};
        filteredPayments.forEach(payment => {
            const month = payment.date ? payment.date.substring(0, 7) : 'Unknown'; // YYYY-MM
            if (!data[month]) data[month] = 0;
            data[month] += payment.amount;
        });
        return Object.entries(data).map(([month, total]) => ({ month, total }));
    }, [filteredPayments]);

    const headers = [
        { label: 'Customer Details', key: 'customerName', width: '25%' },
        { label: 'Box Number', key: 'boxNumber', width: '15%' },
        { label: 'Amount', key: 'totalAmountPending', width: '15%' },
        { label: 'Payment Mode', key: 'mode', width: '20%' },
        { label: 'Due Date', key: 'billDueDate', width: '15%' },
        { label: 'Status', key: 'billStatus', width: '10%' },
        {
            label: 'Actions',
            key: 'actions',
            width: '10%',
            render: (_, payment) => (
                <div className="flex justify-center space-x-2">
                    {payment.totalAmountPending > 0 && (
                        <Button
                            colorScheme="green"
                            onClick={() => handleOpenConfirmModal(payment.customerId)}
                        >
                            Confirm Payment
                        </Button>
                    )}
                </div>
            )
        }
    ];

    const handleSubmitPaymentForm = (formData) => {
        authAxios.post('/api/payments', formData)
            .then(response => {
                toast.success('Payment added successfully!');
                fetchPayments(); // Refresh the payments list
                handleCloseModal(); // Close the modal
            })
            .catch(error => {
                toast.error('Failed to add payment');
                console.error('Error adding payment:', error);
            });
    };

    const handleBulkSelect = useCallback((newSelectedRows) => {
        console.log('Bulk select called with:', newSelectedRows);
        setSelectedPaymentIds(newSelectedRows);
    }, []);

    return (
        <div className="space-y-6 p-6">
            <h1 className="text-2xl font-bold">Payment Details</h1>

            {loading ? (
                <Loader />
            ) : (
                <>
                    {/* Payment Statistics */}
                    <PaymentStats payments={filteredPayments}/>

                    <div className="flex justify-between items-center flex-wrap gap-4">
                        <Button
                            colorScheme='blue'
                            onClick={handleOpenModal}
                        >
                            Add New Payment
                        </Button>
                        <Button
                            colorScheme='blue'
                            onClick={exportToExcel}
                        >
                            Export Paid Customers
                        </Button>
                        {selectedPaymentIds.length > 0 && (
                            <Button
                                colorScheme='green'
                                onClick={handleClearPayments}
                            >
                                Confirm Payments ({selectedPaymentIds.length})
                            </Button>
                        )}
                    </div>

                    {/* Payment Table */}
                    <Table
                        headers={headers}
                        data={preparedPayments}
                        selectable={true}
                        onBulkSelect={handleBulkSelect}
                        selectedRows={selectedPaymentIds}
                        onCellClick={(row, key) => {
                            if (key !== 'actions') {
                                handleOpenModal(row);
                            }
                        }}
                        onSort={(key) => requestSort(key)}
                    />

                    {/* Confirm Payment Modal */}
                    <ConfirmPaymentModal
                        isOpen={showConfirmModal}
                        onRequestClose={handleCloseConfirmModal}
                        customerId={selectedCustomerId}
                        onConfirm={handleConfirmPayments}
                    />

                    {/* Payment Trends */}
                    {chartData.length > 0 ? (
                        <div>
                            <h2 className="text-xl font-bold mb-4">Payment Trends</h2>
                            <div className="h-80 w-full">
                                <ResponsiveContainer width="100%" height="100%">
                                    <LineChart data={chartData}>
                                        <CartesianGrid strokeDasharray="3 3"/>
                                        <XAxis dataKey="month"/>
                                        <YAxis/>
                                        <Tooltip/>
                                        <Legend/>
                                        <Line type="monotone" dataKey="total" stroke="#8884d8"/>
                                    </LineChart>
                                </ResponsiveContainer>
                            </div>
                        </div>
                    ) : (
                        <div className="text-center py-8 text-gray-500">
                            No data available for the selected date range.
                        </div>
                    )}

                    {/* Add Payment Modal */}
                    <Modal
                        isOpen={showModal}
                        onRequestClose={handleCloseModal}
                        contentLabel="Add New Payment"
                    >
                        <PaymentForm onSubmit={handleSubmitPaymentForm} onClose={handleCloseModal}/>
                    </Modal>
                </>
            )}

            {error && <p className="text-red-500 p-4">{error}</p>}
        </div>
    );
};

export default PaymentsPage;

