// client/src/components/BulkUpload.js

import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';
import Modal from './Modal';

const BulkUpload = ({ onUploadSuccess }) => {  // Added onUploadSuccess prop for refreshing data
    const { authAxios } = useContext(AuthContext);
    const [file, setFile] = useState(null);
    const [loading, setLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [error, setError] = useState(null);
    const [failedCustomers, setFailedCustomers] = useState([]);
    const [showFailedModal, setShowFailedModal] = useState(false);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setError(null); // Reset error when new file is selected
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!file) {
            setError('Please select a file to upload.');
            toast.error('Please select a file to upload.');
            return;
        }

        setLoading(true);
        setError(null);
        setProgress(0);
        setFailedCustomers([]); // Reset failed customers

        const formData = new FormData();
        formData.append('file', file);

        const toastId = toast.info('Uploading...', { autoClose: false });

        try {
            const response = await authAxios.post('/api/customers/bulk-upload', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setProgress(percentCompleted);
                    toast.update(toastId, {
                        render: `Uploading... ${percentCompleted}%`,
                        type: 'info',
                    });
                },
            });

            const failed = response.data.filter(result => result.status !== 'success');
            setFailedCustomers(failed);

            if (failed.length > 0) {
                setShowFailedModal(true);
                toast.warn(`Upload completed with ${failed.length} errors.`);
            } else {
                toast.update(toastId, {
                    render: 'Bulk upload successful',
                    type: 'success',
                    autoClose: 5000,
                });

                if (onUploadSuccess) {
                    onUploadSuccess();  // Refresh data
                }

                setFile(null);  // Reset the file input
            }
        } catch (err) {
            console.error('Error in bulk upload:', err);
            setError('Failed to upload. Please try again.');
            toast.update(toastId, {
                render: 'Failed to upload. Please try again.',
                type: 'error',
                autoClose: 5000,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4">
            <h2 className="text-2xl font-bold mb-4">Bulk Upload Customers</h2>
            <form onSubmit={handleSubmit}>
                <div className="mb-4">
                    <input
                        type="file"
                        onChange={handleFileChange}
                        accept=".xlsx, .xls"
                        className="block w-full text-sm text-gray-500
                            file:mr-4 file:py-2 file:px-4
                            file:rounded-full file:border-0
                            file:text-sm file:font-semibold
                            file:bg-blue-50 file:text-blue-700
                            hover:file:bg-blue-100 transition-transform transform hover:scale-105"
                    />
                </div>
                <button
                    type="submit"
                    className={`bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105 ${
                        loading ? 'cursor-not-allowed opacity-50' : ''
                    }`}
                    disabled={loading}
                >
                    {loading ? `Uploading... ${progress}%` : 'Upload'}
                </button>
            </form>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {failedCustomers.length > 0 && (
                <p className="text-red-500 mt-4">Some customers failed to upload.</p>
            )}

            {/* Modal for Failed Uploads */}
            {showFailedModal && (
                <Modal
                    isOpen={showFailedModal}
                    onRequestClose={() => setShowFailedModal(false)}
                    contentLabel="Failed Uploads"
                >
                    <h3 className="text-xl font-bold mb-2">Failed Uploads</h3>
                    <ul className="list-disc pl-5">
                        {failedCustomers.map((result, index) => (
                            <li key={index} className="text-red-500">
                                {result.customerId}: {result.message}
                            </li>
                        ))}
                    </ul>
                </Modal>
            )}
        </div>
    );
};

export default BulkUpload;

