// client/src/pages/SetTopBox/BulkUploadModal.js

import React from 'react';
import Modal from '../../components/Modal';
import SetTopBoxBulkUpload from '../../components/SetTopBoxBulkUpload';

const BulkUploadModal = ({ showModal, handleCloseModal, uploadMode, setUploadMode, fetchSetTopBoxes }) => {
    return (
        <Modal
            isOpen={showModal}
            onRequestClose={handleCloseModal}
            contentLabel="Bulk Upload Set Top Boxes"
        >
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={() => setUploadMode('migrate')}
                    className={`px-4 py-2 rounded focus:outline-none ${uploadMode === 'migrate' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                >
                    Migrate Set Top Boxes
                </button>
                <button
                    onClick={() => setUploadMode('new')}
                    className={`px-4 py-2 rounded focus:outline-none ${uploadMode === 'new' ? 'bg-blue-500 text-white' : 'bg-gray-300 text-black'}`}
                >
                    Add New Set Top Boxes
                </button>
            </div>

            <SetTopBoxBulkUpload
                endpoint={uploadMode === 'migrate' ? '/api/settopboxes/assign-bulk' : '/api/settopboxes/bulk-upload'}
                onUploadSuccess={fetchSetTopBoxes}
            />
        </Modal>
    );
};

export default BulkUploadModal;
