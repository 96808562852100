// client/src/components/Billing/PaymentDetailsTable.js

import React, { useState } from 'react';
import Table from '../Table';

const PaymentDetailsTable = ({ paymentDetails }) => {
    const [selectedRows, setSelectedRows] = useState([]);

    const headers = [
        {
            label: 'Select',
            key: 'select',
            render: (row) => (
                <input
                    type="checkbox"
                    checked={selectedRows.includes(row._id)}
                    onChange={() => handleRowSelect(row._id)}
                />
            ),
            sortable: false,
            width: '75px',
        },
        { label: 'Date', key: 'date' },
        { label: 'Amount', key: 'amount' },
        { label: 'Mode', key: 'mode' },
        { label: 'Receipt Number', key: 'receiptNumber' },
        { label: 'Remarks', key: 'remarks' },
    ];

    const data = paymentDetails.map(payment => ({
        ...payment,
        date: new Date(payment.date).toLocaleDateString(),
    }));

    const handleRowSelect = (rowId) => {
        setSelectedRows((prevSelected) =>
            prevSelected.includes(rowId)
                ? prevSelected.filter((id) => id !== rowId)
                : [...prevSelected, rowId]
        );
    };

    return <Table headers={headers} data={data} />;
};

export default PaymentDetailsTable;
