// client/src/components/StatsCard.js

import React from 'react';

const StatsCard = ({
                       title,
                       value,
                       className = '',
                       icon = null,
                       footerText = '',
                       footerIcon = null,
                       cardSize = 'medium', // small, medium, large
                       titleColor = 'text-gray-600',
                       valueColor = 'text-gray-800',
                       titleSize = 'text-lg',
                       valueSize = 'text-4xl',
                       animate = true,
                   }) => {

    // Map cardSize prop to specific Tailwind width and height classes
    const sizeClassMap = {
        small: 'p-4 text-sm',
        medium: 'p-6 text-base',
        large: 'p-8 text-lg',
    };

    const cardSizeClass = sizeClassMap[cardSize] || sizeClassMap.medium; // Default to medium size

    // Apply animation classes if animate is true
    const animationClass = animate
        ? 'transition-transform transform hover:scale-105 hover:shadow-xl'
        : '';

    return (
        <div className={`bg-white shadow-md rounded-lg ${cardSizeClass} text-center ${animationClass} ${className}`}>
            {icon && <div className="mb-4 text-4xl">{icon}</div>}
            <h2 className={`${titleSize} font-bold mb-2 ${titleColor}`}>{title}</h2>
            <div className={`${valueSize} font-bold ${valueColor} mb-4`}>{value}</div>
            {footerText && (
                <div className="flex items-center justify-center text-sm text-gray-500 mt-4">
                    {footerIcon && <span className="mr-2 text-lg">{footerIcon}</span>}
                    <span>{footerText}</span>
                </div>
            )}
        </div>
    );
};

export default StatsCard;
