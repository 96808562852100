// client/src/components/UserManagement.js

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import Modal from '../components/Modal';
import Table from '../components/Table';
import { Button } from '../components/ui/button';
import AuthContext from '../context/AuthContext';
import WebAuthnRegistrationModal from './WebAuthnRegistrationModal';

const UserManagement = () => {
    const { authAxios } = useContext(AuthContext);
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState({ username: '', role: 'manager', password: '' });
    const [enableWebAuthn, setEnableWebAuthn] = useState(false);
    const [showWebAuthnModal, setShowWebAuthnModal] = useState(false);

    useEffect(() => {
        fetchUsers();
        fetchRoles();
    }, [authAxios]);

    const fetchUsers = async () => {
        try {
            const response = await authAxios.get('/api/users');
            setUsers(response.data);
        } catch (error) {
            toast.error('Failed to fetch users');
        }
    };

    const fetchRoles = async () => {
        try {
            const response = await authAxios.get('/api/roles');
            setRoles(response.data);
        } catch (error) {
            toast.error('Failed to fetch roles');
        }
    };

    const handleEditClick = (user) => {
        setSelectedUser(user || { username: '', role: roles.length ? roles[0].name : 'manager', password: '' });
        setEnableWebAuthn(false);
        setShowModal(true);
    };

    const handleAddUserClick = () => {
        setSelectedUser({ username: '', role: roles.length ? roles[0].name : 'manager', password: '' });
        setEnableWebAuthn(false);
        setShowModal(true);
    };

    const handleSaveClick = async () => {
        try {
            let userResponse;
            const updateData = {
                username: selectedUser.username,
                role: selectedUser.role
            };

            if (selectedUser.password) {
                updateData.password = selectedUser.password;
            }

            if (selectedUser._id) {
                userResponse = await authAxios.put(`/api/users/${selectedUser._id}`, updateData);
                toast.success('User updated successfully!');
            } else {
                userResponse = await authAxios.post('/api/users/register', updateData);
                toast.success('User added successfully!');
            }

            fetchUsers();
            setShowModal(false);

            if (enableWebAuthn) {
                setShowWebAuthnModal(true);
            }
        } catch (error) {
            toast.error('Failed to update user');
        }
    };

    const handleDeleteClick = async (userId) => {
        try {
            await authAxios.delete(`/api/users/${userId}`);
            toast.success('User deleted successfully!');
            fetchUsers();
        } catch (error) {
            toast.error('Failed to delete user');
        }
    };

    const handleWebAuthnCheckboxChange = (e) => {
        const checked = e.target.checked;
        setEnableWebAuthn(checked);

        if (checked) {
            setShowWebAuthnModal(true);
        }
    };

    const handleWebAuthnModalCancel = () => {
        setEnableWebAuthn(false);
        setShowWebAuthnModal(false);
    };

    const handleWebAuthnRegistrationSuccess = () => {
        setEnableWebAuthn(true);
        setShowWebAuthnModal(false);
    };

    const handleChange = (e) => {
        setSelectedUser({
            ...selectedUser,
            [e.target.name]: e.target.value,
        });
    };

    const userHeaders = [
        { label: 'Username', key: 'username' },
        { label: 'Role', key: 'role' },
        { label: 'Actions', key: 'actions' },
    ];

    const userData = users.map((user) => ({
        ...user,
        actions: (
            <>
                <Button onClick={() => handleEditClick(user)}>Edit</Button>
                <Button variant="danger" onClick={() => handleDeleteClick(user._id)}>Delete</Button>
            </>
        ),
    }));

    return (
        <div className="space-y-6 p-6">
            <h1 className="text-2xl font-bold">User Management</h1>

            <Table
                headers={userHeaders}
                data={userData}
                onCellClick={() => { }}
            />

            <Button onClick={handleAddUserClick}>Add User</Button>

            <Modal isOpen={showModal} onRequestClose={() => setShowModal(false)} contentLabel="Edit User">
                <div className="space-y-4">
                    <div className="flex flex-col">
                        <label className="font-medium">Username</label>
                        <input
                            type="text"
                            name="username"
                            value={selectedUser?.username || ''}
                            onChange={handleChange}
                            className="border rounded px-3 py-2"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-medium">Password</label>
                        <input
                            type="password"
                            name="password"
                            value={selectedUser?.password || ''}
                            onChange={handleChange}
                            className="border rounded px-3 py-2"
                            placeholder="Leave blank to keep current password"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="font-medium">Role</label>
                        <select
                            name="role"
                            value={selectedUser?.role || (roles.length ? roles[0].name : 'manager')}
                            onChange={handleChange}
                            className="border rounded px-3 py-2"
                        >
                            {roles.map((role) => (
                                <option key={role._id} value={role.name}>
                                    {role.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex items-center">
                        <input
                            type="checkbox"
                            checked={enableWebAuthn}
                            onChange={handleWebAuthnCheckboxChange}
                            className="mr-2"
                        />
                        <label className="font-medium">Enable WebAuthn (Fingerprint) Registration</label>
                    </div>
                </div>
                <div className="flex justify-end mt-4">
                    <Button variant="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button className="ml-2" onClick={handleSaveClick}>Save</Button>
                </div>
            </Modal>

            <WebAuthnRegistrationModal
                isOpen={showWebAuthnModal}
                onClose={handleWebAuthnModalCancel}
                username={selectedUser.username}
                onSuccess={handleWebAuthnRegistrationSuccess}
            />
        </div>
    );
};

export default UserManagement;
