// client/src/components/WebAuthnRegistrationModal.js

import React, { useState, useContext } from 'react';
import Modal from '../components/Modal';
import { Button } from '../components/ui/button';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';

const WebAuthnRegistrationModal = ({ isOpen, onClose, username, onSuccess }) => {
    const { registerWebAuthn } = useContext(AuthContext);
    const [pin, setPin] = useState('');

    const handleRegister = async () => {
        try {
            await registerWebAuthn(username, pin);
            toast.success('WebAuthn registration successful');
            onSuccess();
        } catch (error) {
            toast.error('Failed to register WebAuthn');
        }
    };

    return (
        <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="WebAuthn Registration">
            <div className="space-y-4">
                <h2 className="text-xl font-bold">Register Fingerprint</h2>
                <p>Please place your finger on the fingerprint sensor to register your fingerprint.</p>
                <div className="flex flex-col">
                    <label className="font-medium">Fallback PIN</label>
                    <input
                        type="password"
                        value={pin}
                        onChange={(e) => setPin(e.target.value)}
                        className="border rounded px-3 py-2"
                        placeholder="Enter a fallback PIN"
                    />
                </div>
            </div>
            <div className="flex justify-end mt-4">
                <Button variant="secondary" onClick={onClose}>Cancel</Button>
                <Button className="ml-2" onClick={handleRegister}>Register</Button>
            </div>
        </Modal>
    );
};

export default WebAuthnRegistrationModal;
