// client/src/components/StatusIndicator.js

import React from 'react';

const StatusIndicator = ({ status }) => {
    // Define the color classes based on the status
    const colorClass = status === 'Active' ? 'bg-green-500' :
        status === 'Deactive' ? 'bg-gray-500' :
            status === 'Assigned' ? 'bg-yellow-500' :
                'bg-gray-500'; // Default color

    return (
        <span
            className={`inline-block w-3 h-3 rounded-full ${colorClass}`}
            title={status}
        ></span>
    );
};

export default StatusIndicator;
