import React, { useState, useContext } from 'react';
import Layout from '../Layout';
import Modal from '../../components/Modal';
import CustomerForm from '../../components/CustomerForm';
import AuthContext from '../../context/AuthContext'; // Import AuthContext
import { toast } from 'react-toastify'; // Import toast for notifications
import * as XLSX from 'xlsx'; // Import XLSX for sample file generation

function CustomerRegistration() {
    const { authAxios } = useContext(AuthContext); // Use AuthContext for authenticated Axios instance
    const [showModal, setShowModal] = useState(false);
    const [error, setError] = useState(null);

    const handleOpenModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const handleSubmit = async (formData) => {
        try {
            await authAxios.post('/api/customers', formData);
            toast.success('Customer Registered Successfully');
            handleCloseModal();
        } catch (err) {
            console.error('Error registering customer:', err);
            setError('Failed to register customer. Please try again.');
            toast.error('Failed to register customer. Please try again.');
        }
    };

    return (
        <Layout>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Register New Customer</h1>
                <div className="flex space-x-4">
                    <button
                        onClick={handleOpenModal}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-transform transform hover:scale-105"
                    >
                        Register Customer
                    </button>
                </div>
            </div>

            <Modal isOpen={showModal} onRequestClose={handleCloseModal}>
                <CustomerForm
                    isUpdate={false}
                    onSubmit={handleSubmit}
                    onClose={handleCloseModal}
                />
            </Modal>

            {error && <p className="text-red-500 p-4">{error}</p>}
        </Layout>
    );
}

export default CustomerRegistration;
