// client/src/pages/Payments/ConfirmPaymentModal.js

import React, { useState, useEffect, useContext } from 'react';
import Modal from '../../components/Modal';
import { Checkbox, Button, Select, Textarea } from '@chakra-ui/react';
import { SelectableGroup, createSelectable } from 'react-selectable-fast';
import AuthContext from '../../context/AuthContext';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';

const SelectableBill = createSelectable(({ selectableRef, bill, isSelected, isSelecting, onCheckboxChange }) => (
    <div
        ref={selectableRef}
        className={`flex justify-between items-center p-2 border transition-colors duration-200
            ${isSelected ? 'bg-blue-100' : 'bg-white'}
            ${isSelecting ? 'bg-yellow-100' : ''}`}
    >
        <div className="pointer-events-none">
            <p className="font-semibold">{bill.month}</p>
            <p>Amount Due: ₹{bill.amountDue.toFixed(2)}</p>
        </div>
        <Checkbox
            isChecked={isSelected}
            onChange={() => onCheckboxChange(bill.id)}
            className="ml-4 pointer-events-auto"
        >
            Select
        </Checkbox>
    </div>
));

const ConfirmPaymentModal = ({ isOpen, onRequestClose, customerId, onConfirm }) => {
    const { authAxios, loginWebAuthn, user } = useContext(AuthContext);  // Added loginWebAuthn
    const [bills, setBills] = useState([]);
    const [selectedBills, setSelectedBills] = useState([]);
    const [totalSelectedAmount, setTotalSelectedAmount] = useState(0);
    const [isSelecting, setIsSelecting] = useState(false);
    const [paymentMode, setPaymentMode] = useState('');
    const [remarks, setRemarks] = useState('');

    useEffect(() => {
        if (isOpen) {
            fetchBills();
        }
    }, [isOpen]);

    const fetchBills = async () => {
        try {
            const response = await authAxios.get(`/api/payments/customer/${customerId}/bills`);
            // Filter out bills that are already paid
            const unpaidBills = response.data.filter(bill => bill.status !== 'Paid');
            setBills(unpaidBills);
        } catch (error) {
            console.error('Error fetching bills:', error.message);
            if (error.response) {
                console.error('Server responded with:', error.response.data);
            }
        }
    };

    const handleCheckboxChange = (billId) => {
        setSelectedBills((prevSelected) =>
            prevSelected.includes(billId)
                ? prevSelected.filter(id => id !== billId)
                : [...prevSelected, billId]
        );
    };

    const handleSelectionStart = () => {
        setIsSelecting(true);
    };

    const handleSelectionFinish = (selectedItems) => {
        const selectedIds = selectedItems.map(item => item.props.bill.id);
        setSelectedBills(selectedIds);
        updateTotalAmount(selectedIds);
        autoFillRemarks(selectedIds);
        setIsSelecting(false);
    };

    const updateTotalAmount = (selectedIds) => {
        const total = bills
            .filter(bill => selectedIds.includes(bill.id))
            .reduce((sum, bill) => sum + bill.amountDue, 0);
        setTotalSelectedAmount(total);
    };

    const autoFillRemarks = (selectedIds) => {
        const selectedBillsData = bills.filter(bill => selectedIds.includes(bill.id));
        const paidMonths = selectedBillsData.map(bill => bill.month).join(', ');

        const unpaidBills = bills.filter(bill => !selectedIds.includes(bill.id) && bill.status !== 'Paid');
        const unpaidMonths = unpaidBills.map(bill => bill.month).join(', ');

        const currentDate = dayjs().format('DD-MM-YYYY');
        let remarkText = `Paid for ${paidMonths} on ${currentDate}`;

        if (unpaidMonths) {
            remarkText += ` | Unpaid for ${unpaidMonths}`;
        }

        setRemarks(remarkText);
    };

    const handleConfirmPayments = async () => {
        if (!paymentMode) {
            toast.error("Please select a payment mode.");
            return;
        }

        try {
            if (!user) {
                throw new Error('User not authenticated');
            }

           /* console.log('Attempting WebAuthn login for user:', user.username);
            await loginWebAuthn(user.username);
            console.log('WebAuthn login successful for user:', user.username);*/

            const response = await authAxios.post('/api/payments/confirm', {
                customerId,
                billIds: selectedBills,
                paymentMode,
                remarks
            });

            toast.success('Payments confirmed successfully');
            onConfirm(selectedBills, paymentMode, remarks);
            onRequestClose();
        } catch (error) {
            console.error('Error during WebAuthn or confirming payments:', error);
            if (error.message === 'User not authenticated') {
                toast.error('Please log in to confirm payments');
            } else if (error.message === 'WebAuthn is not set up for this user. Please contact support.') {
                toast.error(error.message);
            } else if (error.response) {
                console.error('Server responded with:', error.response.data);
                toast.error(`Failed to confirm payments: ${error.response.data.message}`);
            } else if (error.request) {
                console.error('No response received:', error.request);
                toast.error('Failed to confirm payments: No response from server');
            } else {
                console.error('Error setting up request:', error.message);
                toast.error(`Failed to confirm payments: ${error.message}`);
            }
        }
    };

    useEffect(() => {
        updateTotalAmount(selectedBills);
    }, [selectedBills, bills]);

    return (
        <Modal isOpen={isOpen} onRequestClose={onRequestClose} contentLabel="Confirm Payment">
            <div>
                <h3 className="text-lg font-bold mb-4">Select Bills to Confirm</h3>
                <SelectableGroup
                    className="selectable-container"
                    clickClassName="tick"
                    enableDeselect
                    onSelectionStart={handleSelectionStart}
                    onSelectionFinish={handleSelectionFinish}
                    tolerance={0}
                    allowClickWithoutSelected
                    ignoreList={['.pointer-events-auto']}
                >
                    <div className="space-y-4">
                        {bills.map(bill => (
                            <SelectableBill
                                key={bill.id}
                                bill={bill}
                                selectableKey={bill.id}
                                isSelected={selectedBills.includes(bill.id)}
                                isSelecting={isSelecting}
                                onCheckboxChange={handleCheckboxChange}
                            />
                        ))}
                    </div>
                </SelectableGroup>
                <div className="mt-4">
                    <p className="text-lg font-bold">Total Selected Amount: ₹{totalSelectedAmount.toFixed(2)}</p>
                </div>
                <div className="mt-4">
                    <label className="font-bold">Payment Mode</label>
                    <Select placeholder="Select payment mode" onChange={(e) => setPaymentMode(e.target.value)}>
                        <option value="Cash">Cash</option>
                        <option value="Online">Online</option>
                        <option value="Card">Card</option>
                    </Select>
                </div>
                <div className="mt-4">
                    <label className="font-bold">Remarks</label>
                    <Textarea
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                        placeholder="Enter remarks"
                        rows={4}
                        resize="vertical"
                    />
                </div>
                <div className="flex justify-end mt-6">
                    <Button colorScheme="blue" onClick={handleConfirmPayments}>
                        Confirm Payments
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmPaymentModal;
