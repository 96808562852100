// client/src/pages/CustomerModal.js

import React, { useState } from 'react';
import Modal from '../../components/Modal';
import CustomerForm from '../../components/CustomerForm';
import BulkUpload from '../../components/BulkUpload';
import StatusIndicator from '../../components/StatusIndicator';
import BillingPaymentDetailsModal from '../../components/Billing/BillingPaymentDetailsModal';

export const CustomerDetailsModal = ({ isOpen, onClose, customer }) => {
    const [showBillingModal, setShowBillingModal] = useState(false);

    const handleOpenBillingModal = () => {
        setShowBillingModal(true);
    };

    const handleCloseBillingModal = () => {
        setShowBillingModal(false);
    };

    return (
        <>
            <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Customer Details">
                {customer && (
                    <div>
                        <h3 className="text-xl font-bold mb-2">{customer.customerName}</h3>
                        <p><strong>Optional ID:</strong> {customer.optionalID || 'N/A'}</p>
                        <p><strong>ID:</strong> {customer.customerId}</p>
                        <p><strong>Address:</strong> {customer.addressLine1}, {customer.addressLine2 || 'N/A'}</p>
                        <p><strong>Phone Number:</strong> {customer.phoneNumber}</p>
                        <p><strong>Box Number:</strong> {customer.boxNumber?.boxNumber || 'N/A'}</p>
                        <p>
                            <strong>Box Status:</strong>
                            {customer.boxNumber ? (
                                <StatusIndicator status={customer.boxNumber.status} />
                            ) : (
                                'N/A'
                            )}
                        </p>
                        <p><strong>Subscription:</strong> {customer.subscriptionPlanName || 'Not Assigned'}</p>

                        {/* Button to open Billing and Payment Details Modal */}
                        <button
                            onClick={handleOpenBillingModal}
                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105 mt-4"
                        >
                            View Billing and Payment Details
                        </button>
                    </div>
                )}
            </Modal>

            {/* Billing and Payment Details Modal */}
            {customer && (
                <BillingPaymentDetailsModal
                    customerId={customer._id}
                    showModal={showBillingModal}
                    handleCloseModal={handleCloseBillingModal}
                />
            )}
        </>
    );
};

export const CustomerEditModal = ({ isOpen, onClose, onSubmit, customer, nodes, setTopBoxes }) => (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Update Customer" onSubmit={onSubmit}>
        <CustomerForm
            isUpdate={true}
            customerData={{
                ...customer,
                boxNumber: customer?.boxNumber?.boxNumber || 'N/A',
                subscriptionPlan: customer?.subscriptionPlanName || 'Not Assigned',
            }}
            nodes={nodes}
            setTopBoxes={setTopBoxes}
            fetchNodes={() => {}}
            fetchSetTopBoxes={() => {}}
            onSubmit={onSubmit}
            onClose={onClose}
        />
    </Modal>
);

export const BulkUploadModal = ({ isOpen, onClose, generateSampleXLSX }) => (
    <Modal isOpen={isOpen} onRequestClose={onClose} contentLabel="Bulk Upload Customers">
        <BulkUpload />
        <div className="flex justify-between mt-4">
            <button
                onClick={generateSampleXLSX}
                className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Download Sample XLSX
            </button>
            <button
                onClick={onClose}
                className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
                Cancel
            </button>
        </div>
    </Modal>
);
