import React, { useState } from 'react';
import axios from 'axios';

function RegisterPage() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [role, setRole] = useState('manager');

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.post('/api/users/register', { username, password, role });
            alert('Registration successful');
        } catch (err) {
            alert('Registration failed');
        }
    };

    return (
        <div className="min-h-screen bg-background flex flex-col justify-center items-center">
            <div className="bg-white p-8 rounded-lg shadow-lg text-center w-96">
                <h1 className="text-3xl font-bold text-primary mb-6">Register</h1>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-700 mb-2">Username</label>
                        <input
                            type="text"
                            placeholder="Username"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2">Password</label>
                        <input
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        />
                    </div>
                    <div>
                        <label className="block text-gray-700 mb-2">Role</label>
                        <select
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            className="w-full px-3 py-2 border rounded-md"
                        >
                            <option value="manager">Manager</option>
                            <option value="field_agent">Field Agent</option>
                        </select>
                    </div>
                    <button type="submit" className="bg-secondary text-white px-4 py-2 rounded-md hover:bg-secondary-dark transition duration-300">
                        Register
                    </button>
                </form>
            </div>
        </div>
    );
}

export default RegisterPage;
