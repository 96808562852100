// client/src/components/Billing/BillingDetailsTable.js

import React from 'react';
import Table from '../Table';

const BillingDetailsTable = ({ billingDetails }) => {
    const headers = [
        { label: 'Date', key: 'date' },
        { label: 'Amount Due', key: 'amountDue' },
        { label: 'Amount Paid', key: 'amountPaid' },
        { label: 'Status', key: 'status' },
        { label: 'Due Date', key: 'dueDate' },
        { label: 'Remarks', key: 'remarks' },
    ];

    const data = billingDetails.map(bill => ({
        ...bill,
        date: new Date(bill.date).toLocaleDateString(),
        dueDate: new Date(bill.dueDate).toLocaleDateString(),
    }));

    return <Table headers={headers} data={data} selectable={true} />;
};

export default BillingDetailsTable;
