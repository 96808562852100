// client/src/pages/SetTopBox/CustomerDetailsModal.js

import React, { useState } from 'react';
import Modal from '../../components/Modal';
import BillingPaymentDetailsModal from '../../components/Billing/BillingPaymentDetailsModal'; // Ensure you have this component

const CustomerDetailsModal = ({ customerDetails, showCustomerModal, handleCloseCustomerModal }) => {
    const [showBillingModal, setShowBillingModal] = useState(false);

    const handleOpenBillingModal = () => {
        setShowBillingModal(true);
    };

    const handleCloseBillingModal = () => {
        setShowBillingModal(false);
    };

    if (!customerDetails) {
        return null;
    }

    return (
        <>
            <Modal
                isOpen={showCustomerModal}
                onRequestClose={handleCloseCustomerModal}
                contentLabel="Customer Details"
            >
                <div className="p-4">
                    <h2 className="text-xl font-bold mb-4">Customer Details</h2>
                    <p><strong>Name:</strong> {customerDetails.customerName}</p>
                    <p><strong>ID:</strong> {customerDetails.customerId}</p>
                    <p><strong>Address:</strong> {customerDetails.addressLine1}, {customerDetails.addressLine2}</p>
                    <p><strong>Phone Number:</strong> {customerDetails.phoneNumber}</p>
                    <p><strong>Monthly Subscription Fee:</strong> ₹{customerDetails.monthlySubscriptionFee}</p>

                    {/* Button to open Billing and Payment Details Modal */}
                    <button
                        onClick={handleOpenBillingModal}
                        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105 mt-4"
                    >
                        View Billing and Payment Details
                    </button>

                    <button
                        onClick={handleCloseCustomerModal}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105 mt-4 ml-4"
                    >
                        Close
                    </button>
                </div>
            </Modal>

            {/* Billing and Payment Details Modal */}
            {customerDetails && (
                <BillingPaymentDetailsModal
                    customerId={customerDetails._id}
                    showModal={showBillingModal}
                    handleCloseModal={handleCloseBillingModal}
                />
            )}
        </>
    );
};

export default CustomerDetailsModal;
