// src/components/Loader.js

import React, { useState, useEffect } from 'react';
import './Loader.css';

const Loader = ({ text, showTimer = false }) => {
    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        if (showTimer) {
            const timer = setInterval(() => {
                setElapsedTime(prevTime => prevTime + 1);
            }, 1000);

            return () => clearInterval(timer);
        }
    }, [showTimer]);

    return (
        <div className="loader flex flex-col items-center space-y-2">
            <div className="spinner"></div>
            {text && <span className="text-sm font-medium">{text}</span>}
            {showTimer && <span className="text-xs text-gray-500">Loading for {elapsedTime} seconds</span>}
        </div>
    );
};

export default Loader;
