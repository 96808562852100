// client/src/components/ControlPanel.js

import React, { useState } from 'react';
import { FaCog, FaTimes } from 'react-icons/fa';

function ControlPanel({ showNodes, setShowNodes, showCustomers, setShowCustomers, showConnections, setShowConnections, lineColor, setLineColor, onLogout }) {
    const [isOpen, setIsOpen] = useState(false);

    const togglePanel = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className="relative">
            {/* Toggle Button */}
            <button
                onClick={togglePanel}
                className="bg-blue-500 text-white p-3 rounded-full shadow-md hover:bg-blue-600 transition duration-300 z-50 relative"
                style={{ zIndex: 9999 }}
            >
                {isOpen ? <FaTimes size={20} /> : <FaCog size={20} />}
            </button>

            {/* Panel */}
            {isOpen && (
                <div className="absolute right-0 mt-2 w-64 p-4 bg-white shadow-lg rounded-lg" style={{ zIndex: 9998 }}>
                    <h2 className="text-xl font-bold mb-4">Map Controls</h2>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={showNodes}
                                onChange={() => setShowNodes(!showNodes)}
                                className="mr-2"
                            />
                            Show Nodes
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={showCustomers}
                                onChange={() => setShowCustomers(!showCustomers)}
                                className="mr-2"
                            />
                            Show Customers
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="flex items-center">
                            <input
                                type="checkbox"
                                checked={showConnections}
                                onChange={() => setShowConnections(!showConnections)}
                                className="mr-2"
                            />
                            Show Connections
                        </label>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-2">
                            Node Line Color:
                        </label>
                        <input
                            type="color"
                            value={lineColor}
                            onChange={(e) => setLineColor(e.target.value)}
                            className="w-full"
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default ControlPanel;