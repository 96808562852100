// client/src/pages/MapMarkers.js

import React from 'react';
import { Marker, Rectangle, Popup } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { faServer } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import { dom } from '@fortawesome/fontawesome-svg-core';

// Add the icon to the library
library.add(faServer);
dom.watch(); // Re-render icons

// Function to get color based on node status
function getNodeColor(status) {
    switch (status) {
        case 'Active':
            return "#00c900"; // Green
        case 'Inactive':
            return "rgba(119,119,119,0.7)"; // Grayish
        case 'Maintenance':
            return "#ffff00"; // Yellow
        default:
            return "#000000"; // Default to black or any neutral color
    }
}

function MapMarkers({ nodes, showNodes }) {
    return (
        <>
            {/* Render node markers */}
            {showNodes && nodes.map((node) => {
                if (node.latitude !== undefined && node.longitude !== undefined) {
                    // Create a custom icon for each node based on its status
                    const dynamicNodeIcon = L.divIcon({
                        className: 'custom-node-icon',
                        html: `<div style="font-size: 24px; color: ${getNodeColor(node.status)};"><i class="fas fa-server"></i></div>`,
                        iconSize: [24, 24],
                        iconAnchor: [12, 12], // Centered anchor
                        popupAnchor: [0, -12], // Popup should open above the icon
                    });

                    return (
                        <Marker
                            key={node.nodeNumber}
                            position={[node.latitude, node.longitude]}
                            icon={dynamicNodeIcon} // Use the dynamic icon with the color based on status
                        >
                            <Popup>
                                <div>
                                    <h3>Node: {node.nodeNumber}</h3>
                                    <p>Status: {node.status}</p>
                                    {node.hostedBy && <p>Hosted by: {node.hostedBy}</p>}
                                </div>
                            </Popup>
                        </Marker>
                    );
                }
                return null;
            })}
        </>
    );
}

export default MapMarkers;

