// client/src/pages/CustomerStatistics.js

import React from 'react';

const CustomerStatistics = ({ stats }) => {
    if (!stats) return null;

    return (
        <div className="grid grid-cols-4 gap-4 mb-8">
            <div className="bg-white shadow-md rounded-lg p-4 text-center">
                <h2 className="text-lg font-bold">Total Customers</h2>
                <p className="text-4xl font-bold">{stats.total}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 text-center">
                <h2 className="text-lg font-bold">Active Customers</h2>
                <p className="text-4xl font-bold">{stats.active}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 text-center">
                <h2 className="text-lg font-bold">Inactive Customers</h2>
                <p className="text-4xl font-bold">{stats.deactive}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 text-center">
                <h2 className="text-lg font-bold">Paid Customers</h2>
                <p className="text-4xl font-bold">{stats.paid}</p>
            </div>
            <div className="bg-white shadow-md rounded-lg p-4 text-center">
                <h2 className="text-lg font-bold">Unpaid Customers</h2>
                <p className="text-4xl font-bold">{stats.unpaid}</p>
            </div>
        </div>
    );
};

export default CustomerStatistics;

