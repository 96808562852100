// client/src/components/BillingSettings.js

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../components/ui/button';
import Loader from '../components/Loader';  // Updated Loader component
import AuthContext from '../context/AuthContext';

const BillingSettings = () => {
    const { authAxios } = useContext(AuthContext);
    const [billingSettings, setBillingSettings] = useState({
        billingStartsFrom: '',
    });
    const [isSyncing, setIsSyncing] = useState(false);

    useEffect(() => {
        const fetchBillingSettings = async () => {
            try {
                const response = await authAxios.get('/api/settings/system');
                const settingsData = response.data || {};

                const formattedDate = settingsData.billingStartsFrom
                    ? new Date(settingsData.billingStartsFrom).toISOString().split('T')[0]
                    : '';

                setBillingSettings({
                    billingStartsFrom: formattedDate,
                });
            } catch (error) {
                toast.error('Failed to fetch billing settings');
            }
        };
        fetchBillingSettings();
    }, [authAxios]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setBillingSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await authAxios.put('/api/settings/system', billingSettings);
            toast.success('Billing settings updated successfully!');
        } catch (error) {
            toast.error('Failed to update billing settings');
        }
    };

    const handleSyncBills = async () => {
        setIsSyncing(true);
        try {
            const response = await authAxios.put('/api/settings/sync-bills');
            toast.success(`Bill sync completed: ${response.data.results.length} bills created.`);
        } catch (error) {
            toast.error('Failed to sync bills');
        } finally {
            setIsSyncing(false);
        }
    };

    return (
        <div className="space-y-6 p-6">
            <h1 className="text-2xl font-bold">Billing Settings</h1>

            <div className="flex flex-col space-y-4">
                <div>
                    <label className="font-medium">Billing Starts From</label>
                    <input
                        type="date"
                        name="billingStartsFrom"
                        value={billingSettings.billingStartsFrom}
                        onChange={handleChange}
                        className="border rounded px-3 py-2 w-full"
                    />
                </div>

                <div className="flex justify-end">
                    <Button onClick={handleSave}>Save Billing Settings</Button>
                </div>

                <div className="flex justify-end">
                    {isSyncing ? (
                        <Loader text="Loading data..." showTimer={true} />
                    ) : (
                        <Button onClick={handleSyncBills} disabled={isSyncing}>
                    Sync Bills
                </Button>
                )}
            </div>
        </div>
</div>
);
};

export default BillingSettings;



