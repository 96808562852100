// client/src/components/Table.js

import React, { useState, useEffect } from 'react';
import { FaSort, FaSortUp, FaSortDown } from 'react-icons/fa';
import * as XLSX from 'xlsx';

const Table = ({
                   headers,
                   data,
                   onCellClick,
                   rowsPerPageOptions = [5, 10, 20, 50, 100],
                   disableFilters = [],
                   disableSorting = [],
                   selectable = false,
                   onBulkSelect,
                   selectedRows: externalSelectedRows
               }) => {
    const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });
    const [filteredData, setFilteredData] = useState(data);
    const [filters, setFilters] = useState({});
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(rowsPerPageOptions[0]);
    const [suggestions, setSuggestions] = useState({});
    const [selectedRows, setSelectedRows] = useState(externalSelectedRows || []);
    const [lastSelectedIndex, setLastSelectedIndex] = useState(null);
    const [pageInput, setPageInput] = useState('1');

    useEffect(() => {
        let updatedData = data;

        Object.keys(filters).forEach(key => {
            if (filters[key]) {
                updatedData = updatedData.filter(row =>
                    row[key]?.toString().toLowerCase().includes(filters[key].toLowerCase())
                );
            }
        });

        if (sortConfig.key && !disableSorting.includes(sortConfig.key)) {
            updatedData = [...updatedData].sort((a, b) => {
                if (a[sortConfig.key] < b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (a[sortConfig.key] > b[sortConfig.key]) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
        }

        setFilteredData(updatedData);
        setPageInput(currentPage.toString());
    }, [data, filters, sortConfig, disableSorting, currentPage]);

    const handleSort = (key) => {
        let direction = 'ascending';
        if (sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    const getSortIcon = (key) => {
        if (sortConfig.key !== key) return <FaSort />;
        return sortConfig.direction === 'ascending' ? <FaSortUp /> : <FaSortDown />;
    };

    const renderCellContent = (row, header) => {
        if (header.render) {
            return header.render(row[header.key], row);
        }
        return row[header.key];
    };

    const handleFilterChange = (key, value) => {
        setFilters({
            ...filters,
            [key]: value,
        });

        if (value.length >= 3) {
            const uniqueValues = [...new Set(data.map(row => row[key]?.toString().toLowerCase()).filter(val => val.includes(value.toLowerCase())))];
            setSuggestions(prev => ({
                ...prev,
                [key]: uniqueValues
            }));
        } else {
            setSuggestions(prev => ({
                ...prev,
                [key]: []
            }));
        }
    };

    const handleRowsPerPageChange = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setCurrentPage(1);
    };

    const handleSelectRow = (id, index, shiftKey) => {
        let newSelectedRows;
        if (shiftKey && lastSelectedIndex !== null) {
            const start = Math.min(lastSelectedIndex, index);
            const end = Math.max(lastSelectedIndex, index);
            const newSelection = new Set(selectedRows);
            for (let i = start; i <= end; i++) {
                const rowId = paginatedData[i].id;
                if (rowId) {
                    newSelection.add(rowId);
                }
            }
            newSelectedRows = Array.from(newSelection);
        } else {
            newSelectedRows = selectedRows.includes(id)
                ? selectedRows.filter(rowId => rowId !== id)
                : [...selectedRows, id];
        }
        setSelectedRows(newSelectedRows);
        setLastSelectedIndex(index);
        if (onBulkSelect) {
            onBulkSelect(newSelectedRows);
        }
    };

    const handleSelectAll = () => {
        const isFiltered = Object.values(filters).some(filter => filter !== '');
        const dataToSelect = isFiltered ? filteredData : paginatedData;

        let newSelectedRows;
        if (selectedRows.length === dataToSelect.length) {
            newSelectedRows = [];
        } else {
            newSelectedRows = dataToSelect.map(row => row.id);
        }
        setSelectedRows(newSelectedRows);
        if (onBulkSelect) {
            onBulkSelect(newSelectedRows);
        }
    };

    useEffect(() => {
        if (externalSelectedRows) {
            setSelectedRows(externalSelectedRows);
        }
    }, [externalSelectedRows]);

    const paginatedData = filteredData.slice(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / rowsPerPage);

    const handlePageInputChange = (e) => {
        const value = e.target.value;
        setPageInput(value);

        const pageNumber = parseInt(value, 10);
        if (pageNumber && pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const handlePageInputBlur = () => {
        if (pageInput === '' || isNaN(pageInput) || parseInt(pageInput, 10) < 1 || parseInt(pageInput, 10) > totalPages) {
            setPageInput(currentPage.toString());
        }
    };

    const isAllSelected = () => {
        const isFiltered = Object.values(filters).some(filter => filter !== '');
        const dataToCheck = isFiltered ? filteredData : paginatedData;
        return dataToCheck.length > 0 && selectedRows.length === dataToCheck.length;
    };

    const exportSelectedData = () => {
        const selectedData = filteredData.filter(row => selectedRows.includes(row.id));
        const ws = XLSX.utils.json_to_sheet(selectedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Selected Data');
        XLSX.writeFile(wb, 'selected_data.xlsx');
    };

    return (
        <div className="bg-white shadow-md rounded-lg p-4">
            <div className="flex justify-between items-center mb-4">
                <div>
                    <label className="mr-2">Rows per page:</label>
                    <select
                        value={rowsPerPage}
                        onChange={handleRowsPerPageChange}
                        className="border rounded px-2 py-1"
                    >
                        {rowsPerPageOptions.map(option => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="flex justify-between items-center mb-4">
                <div>
                    <p className="text-gray-600">
                        Selected Rows: <span className="font-bold">{selectedRows.length}</span>
                    </p>
                </div>
                <div className="flex items-center space-x-2">
                    <button
                        onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))}
                        disabled={currentPage === 1}
                        className={`px-3 py-1 rounded ${currentPage === 1 ? 'bg-gray-200' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
                    >
                        Previous
                    </button>
                    <span className="mx-2">
            Page
            <input
                type="text"
                value={pageInput}
                onChange={handlePageInputChange}
                onBlur={handlePageInputBlur}
                className="w-12 mx-1 text-center border rounded"
            />
            of {totalPages}
          </span>
                    <button
                        onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))}
                        disabled={currentPage === totalPages}
                        className={`px-3 py-1 rounded ${currentPage === totalPages ? 'bg-gray-200' : 'bg-blue-500 hover:bg-blue-700 text-white'}`}
                    >
                        Next
                    </button>
                </div>
            </div>

            <table className="min-w-full bg-white text-left">
                <thead>
                <tr>
                    {selectable && (
                        <th className="py-2 px-4">
                            <input
                                type="checkbox"
                                onChange={handleSelectAll}
                                checked={isAllSelected()}
                            />
                        </th>
                    )}
                    {headers.map((header, index) => (
                        <th key={index} className="py-2 px-4">
                            <div
                                onClick={() => !disableSorting.includes(header.key) && handleSort(header.key)}
                                className={`flex items-center ${!disableSorting.includes(header.key) && 'cursor-pointer'}`}
                            >
                                {header.label}
                                <span
                                    className="ml-2">{!disableSorting.includes(header.key) && getSortIcon(header.key)}</span>
                            </div>
                            {!disableFilters.includes(header.key) && (
                                <div className="relative">
                                    <input
                                        type="text"
                                        placeholder={`Filter ${header.label}`}
                                        className="mt-2 w-full border rounded px-2 py-1 text-sm focus:outline-none focus:shadow-outline"
                                        onChange={(e) => handleFilterChange(header.key, e.target.value)}
                                        value={filters[header.key] || ''}
                                    />
                                    {suggestions[header.key] && suggestions[header.key].length > 0 && (
                                        <div
                                            className="absolute z-10 bg-white border rounded mt-1 max-h-40 overflow-y-auto">
                                            {suggestions[header.key].map((suggestion, i) => (
                                                <div
                                                    key={i}
                                                    className="px-2 py-1 hover:bg-gray-100 cursor-pointer"
                                                    onClick={() => handleFilterChange(header.key, suggestion)}
                                                >
                                                    {suggestion}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            )}
                        </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {paginatedData.length > 0 ? (
                    paginatedData.map((row, rowIndex) => (
                        <tr
                            key={row.id}
                            className={`text-center border-t ${selectedRows.includes(row.id) ? 'bg-blue-100' : ''}`}
                        >
                            {selectable && (
                                <td className="py-2 px-4">
                                    <input
                                        type="checkbox"
                                        checked={selectedRows.includes(row.id)}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            handleSelectRow(row.id, rowIndex, e.shiftKey);
                                        }}
                                    />
                                </td>
                            )}
                            {headers.map((header, colIndex) => (
                                <td
                                    key={colIndex}
                                    className={`py-2 px-4 cursor-pointer transition-transform transform hover:scale-105 ${selectedRows.includes(row.id) ? 'hover:bg-blue-200' : 'hover:bg-gray-100'}`}
                                    onClick={(e) => {
                                        if (header.key !== 'select' && onCellClick) {
                                            onCellClick(row, header.key);
                                        }
                                    }}
                                    onDoubleClick={(e) => {
                                        handleSelectRow(row.id, rowIndex, e.shiftKey);
                                    }}
                                >
                                    {renderCellContent(row, header)}
                                </td>
                            ))}
                        </tr>
                    ))
                ) : (
                    <tr>
                        <td colSpan={headers.length + (selectable ? 1 : 0)} className="py-4 text-center text-gray-500">
                            No data found
                        </td>
                    </tr>
                )}
                </tbody>
            </table>

            <div className="flex justify-between items-center mt-4">
                <div>
                    <p className="text-gray-600">
                        Selected Rows: <span className="font-bold">{selectedRows.length}</span>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Table;
