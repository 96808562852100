// client/src/components/Timeline.js

import React, { useState } from 'react';
import { FaMoneyBillWave, FaCheckCircle, FaTv, FaExclamationTriangle } from 'react-icons/fa';
import '../styles/timeline.css';

const Timeline = ({ logs }) => {
    const [expandedLogIndex, setExpandedLogIndex] = useState(null);

    const getIconForEvent = (type) => {
        switch (type) {
            case 'Payment':
                return <FaCheckCircle className="text-green-500" />;
            case 'Bill':
                return <FaMoneyBillWave className="text-blue-500" />;
            case 'SetTopBox':
                return <FaTv className="text-purple-500" />;
            case 'Critical':
                return <FaExclamationTriangle className="text-red-500" />;
            default:
                return <FaExclamationTriangle className="text-gray-500" />;
        }
    };

    const getTitleForEvent = (log) => {
        switch (log.type) {
            case 'SetTopBox':
                return `SetTopBox - ${log.details.status}`;
            case 'Bill':
                return `Bill - ${log.details.status === 'Pending' ? 'Generated' : 'Paid'}`;
            default:
                return log.type;
        }
    };

    const toggleDetails = (index) => {
        setExpandedLogIndex(expandedLogIndex === index ? null : index);
    };

    return (
        <div className="timeline-container">
            <div className="timeline">
                {logs.map((log, index) => (
                    <div key={index} className={`timeline-event timeline-item ${log.type.toLowerCase()}`}>
                        <div className="timeline-date">
                            {new Date(log.date).toLocaleDateString(undefined, { month: 'short', year: 'numeric' })}
                        </div>
{/*                        <div className="timeline-icon">
                            {getIconForEvent(log.type)}
                        </div>*/}
                        <div className="timeline-content">
                            <div
                                className="timeline-summary cursor-pointer"
                                onClick={() => toggleDetails(index)}
                            >
                                <h3 className="text-lg font-semibold inline-flex items-center">
                                    {getIconForEvent(log.type)} <span className="ml-2">{getTitleForEvent(log)}</span>
                                </h3>
                            </div>
                            {expandedLogIndex === index && (
                                <div className="timeline-details mt-4 bg-gray-100 p-4 rounded-lg shadow-md">
                                    <pre className="text-sm text-gray-700">{JSON.stringify(log.details, null, 2)}</pre>
                                </div>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Timeline;
