// client/src/components/QRCodeComponent.js

import React from 'react';
import QRCode from 'qrcode.react';

const QRCodeComponent = ({ value }) => {
    return (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
            <QRCode value={value} size={128} />
            <p>{value}</p>
        </div>
    );
};

export default QRCodeComponent;
