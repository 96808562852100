// client/src/components/SystemSettings.js

import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import { Button } from '../components/ui/button';
import AuthContext from '../context/AuthContext';

const SystemSettings = () => {
    const { authAxios } = useContext(AuthContext);
    const [systemSettings, setSystemSettings] = useState({
        companyName: '',
        siteName: '',
        allowRegistration: true,
        maxUsers: 100,
    });

    useEffect(() => {
        const fetchSystemSettings = async () => {
            try {
                const response = await authAxios.get('/api/settings/system');
                setSystemSettings(response.data || {});
            } catch (error) {
                toast.error('Failed to fetch system settings');
            }
        };
        fetchSystemSettings();
    }, [authAxios]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSystemSettings((prevSettings) => ({
            ...prevSettings,
            [name]: value,
        }));
    };

    const handleSave = async () => {
        try {
            await authAxios.put('/api/settings/system', systemSettings);
            toast.success('System settings updated successfully!');
        } catch (error) {
            toast.error('Failed to update system settings');
        }
    };

    return (
        <div className="space-y-6 p-6">
            <h1 className="text-2xl font-bold">System Settings</h1>

            <div className="flex flex-col space-y-4">
                <div>
                    <label className="font-medium">Company Name</label>
                    <input
                        type="text"
                        name="companyName"
                        value={systemSettings.companyName}
                        onChange={handleChange}
                        className="border rounded px-3 py-2 w-full"
                    />
                </div>
                <div>
                    <label className="font-medium">Site Name</label>
                    <input
                        type="text"
                        name="siteName"
                        value={systemSettings.siteName}
                        onChange={handleChange}
                        className="border rounded px-3 py-2 w-full"
                    />
                </div>
                <div>
                    <label className="font-medium">Allow User Registration</label>
                    <select
                        name="allowRegistration"
                        value={systemSettings.allowRegistration}
                        onChange={handleChange}
                        className="border rounded px-3 py-2 w-full"
                    >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                    </select>
                </div>
                <div>
                    <label className="font-medium">Max Users</label>
                    <input
                        type="number"
                        name="maxUsers"
                        value={systemSettings.maxUsers}
                        onChange={handleChange}
                        className="border rounded px-3 py-2 w-full"
                    />
                </div>

                <div className="flex justify-end">
                    <Button onClick={handleSave}>Save System Settings</Button>
                </div>
            </div>
        </div>
    );
};

export default SystemSettings;
