// client/src/components/SetTopBulkUpload.js

import React, { useState, useContext } from 'react';
import AuthContext from '../context/AuthContext';
import { toast } from 'react-toastify';

function SetTopBoxBulkUpload({ endpoint, onUploadSuccess }) {
    const { authAxios } = useContext(AuthContext);
    const [file, setFile] = useState(null);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadFeedback, setUploadFeedback] = useState('');

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
        setUploadFeedback('');
    };

    const handleFileUpload = async () => {
        if (!file) {
            toast.error("Please select a valid file.");
            return;
        }

        setIsUploading(true);

        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await authAxios.post(endpoint, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201 || response.status === 200) {
                toast.success('File uploaded successfully!');
                setUploadFeedback('File uploaded successfully!');
                onUploadSuccess();
                setFile(null);
            }
        } catch (err) {
            console.error('Error uploading file:', err);
            setUploadFeedback('Error uploading file.');
            toast.error('Error uploading file.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleClearFile = () => {
        setFile(null);
        setUploadFeedback('');
    };

    return (
        <div className="flex flex-col items-center space-y-4">
            <input
                type="file"
                accept=".xlsx, .xls"
                onChange={handleFileChange}
                className="py-2 px-4 border rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
            />
            {file && (
                <>
                    <div className="flex space-x-4">
                        <button
                            onClick={handleFileUpload}
                            disabled={isUploading}
                            className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105 ${
                                isUploading ? 'opacity-50 cursor-not-allowed' : ''
                            }`}
                        >
                            {isUploading ? 'Uploading...' : 'Upload File'}
                        </button>
                        <button
                            onClick={handleClearFile}
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                        >
                            Clear File
                        </button>
                    </div>
                    {uploadFeedback && <p className="text-red-500 mt-2">{uploadFeedback}</p>}
                </>
            )}
        </div>
    );
}

export default SetTopBoxBulkUpload;
