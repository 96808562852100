// client/src/components/PaymentForm.js

import React, { useState, useRef, useCallback, useEffect, useContext } from 'react';
import Webcam from 'react-webcam';
import jsQR from 'jsqr';
import { toast } from 'react-toastify';
import AuthContext from '../context/AuthContext';
import { Checkbox, Button } from '@chakra-ui/react';
import dayjs from 'dayjs';

const PaymentForm = ({ onSubmit, onClose }) => {
    const { authAxios } = useContext(AuthContext);
    const [formData, setFormData] = useState({
        customerId: '',
        customerIdDisplay: '',
        boxNumber: '',
        boxNumberDisplay: '',
        amount: '',
        mode: 'Cash',
        date: new Date().toISOString().split('T')[0],
        status: 'Pending',
        remarks: ''
    });

    const [typingTimeout, setTypingTimeout] = useState(null);
    const webcamRef = useRef(null);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceIndex, setSelectedDeviceIndex] = useState(0);
    const [lastTapTime, setLastTapTime] = useState(0);
    const [bills, setBills] = useState([]);
    const [selectedBills, setSelectedBills] = useState([]);
    const [isCameraActive, setIsCameraActive] = useState(true);
    const [isCustomerDataRetrieved, setIsCustomerDataRetrieved] = useState(false);

    useEffect(() => {
        const getDevices = async () => {
            const mediaDevices = await navigator.mediaDevices.enumerateDevices();
            const videoDevices = mediaDevices.filter(device => device.kind === 'videoinput');
            setDevices(videoDevices);
        };
        getDevices();
    }, []);

    const handleScan = useCallback(() => {
        if (!isCameraActive) return;

        const imageSrc = webcamRef.current.getScreenshot();
        if (imageSrc) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            const img = new Image();
            img.src = imageSrc;
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                context.drawImage(img, 0, 0, img.width, img.height);
                const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
                const code = jsQR(imageData.data, imageData.width, imageData.height);
                if (code) {
                    handleBoxNumberChange(code.data);
                    setIsCameraActive(false);
                }
            };
        }
    }, [webcamRef, isCameraActive]);

    useEffect(() => {
        let interval;
        if (isCameraActive) {
            interval = setInterval(() => {
                handleScan();
            }, 1000);
        }
        return () => clearInterval(interval);
    }, [handleScan, isCameraActive]);

    const handleBoxNumberChange = (boxNumber) => {
        setFormData((prevData) => ({ ...prevData, boxNumber }));

        if (boxNumber.length >= 3) {
            if (typingTimeout) {
                clearTimeout(typingTimeout);
            }
            setTypingTimeout(setTimeout(async () => {
                try {
                    const response = await authAxios.get(`/api/customers/box/${boxNumber}`);
                    const customer = response.data;
                    setFormData((prevData) => ({
                        ...prevData,
                        customerId: customer._id,
                        customerIdDisplay: customer.customerId,
                        boxNumber: customer.boxNumber._id,
                        boxNumberDisplay: customer.boxNumber.boxNumber,
                    }));
                    fetchBills(customer._id);
                    setIsCameraActive(false);
                    setIsCustomerDataRetrieved(true);
                } catch (error) {
                    console.error('Error fetching customer details:', error);
                    toast.error('Error fetching customer details');
                }
            }, 1000));
        }
    };

    const fetchBills = async (customerId) => {
        try {
            const response = await authAxios.get(`/api/payments/customer/${customerId}/bills`);
            const unpaidBills = response.data.filter(bill => bill.status !== 'Paid');
            setBills(unpaidBills);
            setSelectedBills(unpaidBills.map(bill => bill.id));
        } catch (error) {
            console.error('Error fetching bills:', error);
            toast.error('Error fetching bills');
        }
    };

    const handleCameraClick = () => {
        const currentTime = new Date().getTime();
        if (currentTime - lastTapTime < 300) {
            // Double tap detected
            setSelectedDeviceIndex((prevIndex) => (prevIndex + 1) % devices.length);
        }
        setLastTapTime(currentTime);
    };

    const handleBillSelect = (billId) => {
        setSelectedBills(prev =>
            prev.includes(billId) ? prev.filter(id => id !== billId) : [...prev, billId]
        );
    };

    // Update form data when selected bills change
    useEffect(() => {
        const selectedBillsData = bills.filter(bill => selectedBills.includes(bill.id));
        const totalAmount = selectedBillsData.reduce((sum, bill) => sum + bill.amountDue, 0);

        // Determine the payment status
        const status = selectedBills.length === 0
            ? 'Pending'
            : (selectedBills.length === bills.length ? 'Paid' : 'Partial');

        // Autofill remarks
        const paidMonths = selectedBillsData.map(bill => bill.month).join(', ');
        const unpaidBills = bills.filter(bill => !selectedBills.includes(bill.id) && bill.status !== 'Paid');
        const unpaidMonths = unpaidBills.map(bill => bill.month).join(', ');
        const currentDate = dayjs().format('DD-MM-YYYY');
        let remarkText = `Paid for ${paidMonths} on ${currentDate}`;
        if (unpaidMonths) {
            remarkText += ` | Unpaid for ${unpaidMonths}`;
        }

        setFormData(prevData => ({
            ...prevData,
            amount: totalAmount.toFixed(2),
            status,
            remarks: remarkText,
        }));
    }, [selectedBills, bills]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const submissionData = {
            ...formData,
            selectedBills: selectedBills.map(id => id.toString()), // Ensure IDs are sent as strings
            customerId: formData.customerId,
        };

        delete submissionData.customerIdDisplay;
        delete submissionData.boxNumberDisplay;

        onSubmit(submissionData);
    };


    const handleReloadCamera = () => {
        setIsCameraActive(true);
        setIsCustomerDataRetrieved(false);
        setFormData(prevData => ({ ...prevData, boxNumber: '', customerId: '', customerIdDisplay: '' }));
        setBills([]);
        setSelectedBills([]);
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-4">
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    {isCameraActive ? 'Scan QR Code (Double tap to switch camera)' : 'QR Code Scanned'}
                </label>
                {isCameraActive ? (
                    <div onClick={handleCameraClick}>
                        <Webcam
                            ref={webcamRef}
                            screenshotFormat="image/png"
                            videoConstraints={{
                                deviceId: devices[selectedDeviceIndex]?.deviceId
                            }}
                            style={{width: '100%'}}
                        />
                    </div>
                ) : (
                    <div className="text-center">
                        <p className="text-green-500 mb-2">
                            {isCustomerDataRetrieved ? 'Customer data retrieved successfully!' : 'QR Code scanned successfully!'}
                        </p>
                        <Button colorScheme="blue" onClick={handleReloadCamera}>
                            Reload Camera
                        </Button>
                    </div>
                )}
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Box Number
                </label>
                <input
                    type="text"
                    name="boxNumber"
                    value={formData.boxNumberDisplay}
                    onChange={(e) => handleBoxNumberChange(e.target.value)}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Customer ID
                </label>
                <input
                    type="text"
                    name="customerIdDisplay"
                    value={formData.customerIdDisplay}
                    onChange={handleChange}
                    readOnly
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                />
            </div>
            <div>
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Select Bills to Pay
                </label>
                <div className="space-y-2 max-h-40 overflow-y-auto">
                    {bills.map(bill => (
                        <div key={bill.id} className="flex items-center">
                            <Checkbox
                                isChecked={selectedBills.includes(bill.id)}
                                onChange={() => handleBillSelect(bill.id)}
                            />
                            <span className="ml-2">
                                {bill.month} - ₹{bill.amountDue.toFixed(2)}
                            </span>
                        </div>
                    ))}
                </div>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Amount
                </label>
                <input
                    type="number"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    readOnly
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                />
                <p className="text-sm text-gray-600 mt-1">Amount is automatically calculated based on selected bills</p>
            </div>

            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Payment Mode
                </label>
                <select
                    name="mode"
                    value={formData.mode}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                >
                    <option value="Cash">Cash</option>
                    <option value="Credit Card">Credit Card</option>
                    <option value="Bank Transfer">Bank Transfer</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Date
                </label>
                <input
                    type="date"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    required
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Status
                </label>
                <input
                    type="text"
                    name="status"
                    value={formData.status}
                    readOnly
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-100"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Remarks
                </label>
                <textarea
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
            </div>

            <div className="flex justify-end">
                <button
                    type="button"
                    onClick={onClose}
                    className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2 focus:outline-none focus:shadow-outline"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Submit
                </button>
            </div>
        </form>
    );
};

export default PaymentForm;
