// client/src/components/pages/paymentStats.js

import React from 'react';
import NumericStats from '../../components/NumericStats';

const PaymentStats = ({ payments }) => {
    const totalPayments = payments.reduce((acc, payment) => acc + payment.amount, 0);
    const successfulPayments = payments.filter(payment => payment.status === 'Paid').length;
    const pendingPayments = payments.filter(payment => payment.status === 'Pending').length;

    return (
        <div className="grid grid-cols-3 gap-4 mb-8">
            <NumericStats title="Total Payments" value={`₹${totalPayments}`} />
            <NumericStats title="Successful Payments" value={successfulPayments} />
            <NumericStats title="Pending Payments" value={pendingPayments} />
        </div>
    );
};

export default PaymentStats;
