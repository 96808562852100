// client/src/components/NumericStats.js

import React from 'react';
import StatsCard from './StatsCard';

const NumericStats = ({ title, value }) => {
    return (
        <StatsCard title={title}>
            <span>{value}</span>
        </StatsCard>
    );
};

export default NumericStats;
