// client/src/pages/CustomersPage.js

import React, { useState, useEffect, useContext, useCallback } from 'react';
import AuthContext from '../../context/AuthContext';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import CustomerStatistics from '../../components/CustomerStatistics';
import { Button } from '../../components/ui/button';
import { Card, CardHeader, CardTitle, CardContent } from '../../components/ui/card';
import { Alert, AlertTitle, AlertDescription } from '../../components/ui/alert';
import CustomerTable from './CustomerTable';
import { CustomerDetailsModal, CustomerEditModal, BulkUploadModal } from './CustomerModal';
import BulkActions from '../../components/BulkActions';
import Modal from '../../components/Modal';
import * as XLSX from 'xlsx';

function CustomersPage() {
    const { authAxios } = useContext(AuthContext);
    const [customers, setCustomers] = useState([]);
    const [filteredCustomers, setFilteredCustomers] = useState([]);
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [showBulkUploadModal, setShowBulkUploadModal] = useState(false);
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [modalMode, setModalMode] = useState('register');
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [nodes, setNodes] = useState([]);
    const [setTopBoxes, setSetTopBoxes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [showBulkAssignModal, setShowBulkAssignModal] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState(null);
    const [customerStats, setCustomerStats] = useState(null);
    const [subscriptionStats, setSubscriptionStats] = useState(null);
    const [revenueStats, setRevenueStats] = useState(null);

    const fetchCustomerStats = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/stats/customers');
            setCustomerStats(response.data);
        } catch (err) {
            console.error('Error fetching customer stats:', err);
            toast.error('Failed to fetch customer stats.');
        }
    }, [authAxios]);

    const fetchSubscriptionStats = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/stats/subscriptions');
            setSubscriptionStats(response.data);
        } catch (err) {
            console.error('Error fetching subscription stats:', err);
            toast.error('Failed to fetch subscription stats.');
        }
    }, [authAxios]);

    const fetchRevenueStats = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/stats/revenue');
            setRevenueStats(response.data);
        } catch (err) {
            console.error('Error fetching revenue stats:', err);
            toast.error('Failed to fetch revenue stats.');
        }
    }, [authAxios]);


    // Fetching customers and related data
    const fetchCustomers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await authAxios.get('/api/customers');
            setCustomers(response.data);
            setFilteredCustomers(response.data);
        } catch (err) {
            setError('Failed to fetch customers.');
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [authAxios]);

    const fetchNodes = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/nodes');
            setNodes(response.data);
        } catch (err) {
            console.error('Error fetching nodes:', err);
        }
    }, [authAxios]);

    const fetchSetTopBoxes = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/settopboxes');
            setSetTopBoxes(response.data);
        } catch (err) {
            console.error('Error fetching set top boxes:', err);
        }
    }, [authAxios]);

    const fetchSubscriptionPlans = useCallback(async () => {
        try {
            const response = await authAxios.get('/api/subscriptionplans');
            setSubscriptionPlans(response.data);
        } catch (err) {
            console.error('Error fetching subscription plans:', err);
        }
    }, [authAxios]);

    useEffect(() => {
        fetchCustomers();
        fetchNodes();
        fetchSetTopBoxes();
        fetchSubscriptionPlans();
        fetchCustomerStats();
        fetchSubscriptionStats();
        fetchRevenueStats();
    }, [fetchCustomers, fetchNodes, fetchSetTopBoxes, fetchSubscriptionPlans, fetchCustomerStats, fetchSubscriptionStats, fetchRevenueStats]);

    const handleRegisterCustomer = () => {
        setModalMode('register');
        setSelectedCustomer(null);
        setShowCustomerModal(true);
    };

    const handleUpdateCustomer = (customer) => {
        setModalMode('update');
        setSelectedCustomer(customer);
        setShowCustomerModal(true);
    };

    const handleShowCustomerDetails = (customer) => {
        setSelectedCustomer(customer);
        setShowDetailsModal(true);
    };

    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false);
        setSelectedCustomer(null);
    };

    const handleCloseDetailsModal = () => {
        setShowDetailsModal(false);
        setSelectedCustomer(null);
    };

    const handleSubmitCustomerForm = async (formData) => {
        try {
            if (modalMode === 'register') {
                await authAxios.post('/api/customers', formData);
                toast.success('Customer Registered Successfully');
            } else {
                await authAxios.put(`/api/customers/${selectedCustomer._id}`, formData);
                toast.success('Customer Updated Successfully');
            }
            handleCloseCustomerModal();
            fetchCustomers();
            fetchCustomerStats();
            fetchSubscriptionStats();
            fetchRevenueStats();
        } catch (err) {
            console.error('Error submitting form:', err);
            setError('Failed to submit form.');
            toast.error('Failed to submit form.');
        }
    };

    const handleOpenBulkUploadModal = () => {
        setShowBulkUploadModal(true);
    };

    const handleCloseBulkUploadModal = () => {
        setShowBulkUploadModal(false);
    };

    const handleDeleteCustomer = async (customer) => {
        if (window.confirm(`Are you sure you want to delete ${customer.customerName}?`)) {
            try {
                await authAxios.delete(`/api/customers/${customer._id}`);
                toast.success('Customer deleted successfully');
                fetchCustomers();
                fetchCustomerStats();
                fetchSubscriptionStats();
                fetchRevenueStats();
            } catch (err) {
                console.error('Error deleting customer:', err);
                toast.error('Failed to delete customer.');
            }
        }
    };

    const generateSampleXLSX = () => {
        const sampleData = [
            {
                customerId: 'CUST001',
                customerName: 'John Doe',
                addressLine1: '123 Main St',
                addressLine2: 'Apt 4B',
                mapAddress: '123 Main St, City, Country',
                phoneNumber: '1234567890',
                status: 'Paid',
                subscription: 'Gold',
                monthlySubscriptionFee: 50,
                setTopBoxProvider: 'Provider A',
                boxNumber: 'STB001',
                nodeNumber: 'NODE001'
            },
        ];

        const ws = XLSX.utils.json_to_sheet(sampleData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Customers");

        XLSX.writeFile(wb, "sample_customers.xlsx");
    };

    const handleBulkSelect = useCallback((selectedIds) => {
        setSelectedCustomerIds(selectedIds);
    }, []);

    const handleSubmitBulkAssignSubscription = async () => {
        if (!selectedPlanId) {
            toast.error('Please select a subscription plan');
            return;
        }
        try {
            await authAxios.post('/api/bulk-actions/bulk-assign-subscriptions', {
                customerIds: selectedCustomerIds,
                subscriptionPlanId: selectedPlanId,
            });
            toast.success('Subscription plan assigned successfully');
            fetchCustomers();
            setShowBulkAssignModal(false);
            fetchCustomerStats();
            fetchSubscriptionStats();
            fetchRevenueStats();
        } catch (err) {
            toast.error('Failed to assign subscription plan');
            console.error(err);
        }
    };

    const handleCloseBulkAssignModal = () => {
        setShowBulkAssignModal(false);
    };

    return (
        <div className="p-4">
            <Card>
                <CardHeader>
                    <CardTitle>Customer Management</CardTitle>
                </CardHeader>
                <CardContent>
                    <div className="flex justify-end mb-4 space-x-4">
                        <Button
                            onClick={handleRegisterCustomer}
                            variant="default"
                            className="font-bold"
                        >
                            Register New Customer
                        </Button>
                        <Button
                            onClick={handleOpenBulkUploadModal}
                            variant="secondary"
                            className="font-bold"
                        >
                            Bulk Upload
                        </Button>
                    </div>

                    {loading ? (
                        <Loader />
                    ) : (
                        <>
                            <CustomerStatistics customers={filteredCustomers} stats={customerStats} />
                            <BulkActions
                                selectedIds={selectedCustomerIds}
                                refreshData={fetchCustomers}
                                actions={{
                                    assignSubscriptions: {
                                        label: "Assign Subscriptions",
                                        fetchAdditionalData: fetchSubscriptionPlans,
                                        renderModalContent: (additionalData, setAdditionalData) => (
                                            <div>
                                                <h3 className="text-lg font-semibold mb-4">Select a Subscription Plan</h3>
                                                <select
                                                    className="border rounded p-2 mb-4 w-full"
                                                    value={selectedPlanId || ''}
                                                    onChange={(e) => setSelectedPlanId(e.target.value)}
                                                >
                                                    <option value="">Select Plan</option>
                                                    {subscriptionPlans.map(plan => (
                                                        <option key={plan._id} value={plan._id}>{plan.planName}</option>
                                                    ))}
                                                </select>
                                            </div>
                                        ),
                                        getPayload: () => ({ subscriptionPlanId: selectedPlanId }),
                                        endpoint: '/api/bulk-actions/bulk-assign-subscriptions',
                                    },
                                    deleteCustomers: {
                                        label: "Delete Customers",
                                        variant: "destructive",
                                        endpoint: '/api/bulk-actions/bulk-delete-customers',
                                    }
                                }}
                                entityType="customer"
                            />

                            <CustomerTable
                                customers={filteredCustomers}
                                setTopBoxes={setTopBoxes}
                                onEdit={handleUpdateCustomer}
                                onDelete={handleDeleteCustomer}
                                onDetails={handleShowCustomerDetails}
                                onBulkSelect={handleBulkSelect}
                            />
                        </>
                    )}

                    {error && (
                        <Alert variant="destructive" className="p-4 mt-4">
                            <AlertTitle>Error</AlertTitle>
                            <AlertDescription>{error}</AlertDescription>
                        </Alert>
                    )}
                </CardContent>
            </Card>

            <CustomerDetailsModal
                isOpen={showDetailsModal}
                onClose={handleCloseDetailsModal}
                customer={selectedCustomer}
            />

            <CustomerEditModal
                isOpen={showCustomerModal}
                onClose={handleCloseCustomerModal}
                onSubmit={handleSubmitCustomerForm}
                customer={selectedCustomer}
                nodes={nodes}
                setTopBoxes={setTopBoxes}
            />

            <BulkUploadModal
                isOpen={showBulkUploadModal}
                onClose={handleCloseBulkUploadModal}
                generateSampleXLSX={generateSampleXLSX}
            />

            <Modal
                isOpen={showBulkAssignModal}
                onRequestClose={handleCloseBulkAssignModal}
                contentLabel="Bulk Assign Subscription Plan"
            >
                <div>
                    <h3 className="text-lg font-semibold mb-4">Select a Subscription Plan</h3>
                    <select
                        className="border rounded p-2 mb-4 w-full"
                        value={selectedPlanId}
                        onChange={(e) => setSelectedPlanId(e.target.value)}
                    >
                        <option value="">Select Plan</option>
                        {subscriptionPlans.map(plan => (
                            <option key={plan._id} value={plan._id}>{plan.planName}</option>
                        ))}
                    </select>
                    <div className="flex justify-end space-x-4">
                        <Button variant="default" onClick={handleCloseBulkAssignModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={handleSubmitBulkAssignSubscription}>
                            Assign
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default CustomersPage;


