// client/src/pages/SetTopBoxPage.js

import ReactDOM from 'react-dom';
import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';
import SetTopBoxForm from './SetTopBoxForm';
import CustomerDetailsModal from '../Customer/CustomerDetailsModal';
import BulkUploadModal from './BulkUploadModal';
import SetTopBoxTable from './SetTopBoxTable';
import QRCode from 'qrcode';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

function SetTopBoxPage() {
    const { authAxios } = useContext(AuthContext);
    const [setTopBoxes, setSetTopBoxes] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [nodes, setNodes] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showCustomerModal, setShowCustomerModal] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [formData, setFormData] = useState({
        id: '',
        customerId: '',
        nodeId: '',
        boxNumber: '',
        setTopBoxProvider: '',
        optionalId: '',
        status: '',
    });
    const [customerDetails, setCustomerDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [uploadMode, setUploadMode] = useState('migrate');

    useEffect(() => {
        fetchSetTopBoxes();
        fetchCustomers();
        fetchNodes();
    }, [authAxios]);

    const fetchNodes = async () => {
        try {
            const response = await authAxios.get('/api/nodes');
            setNodes(response.data);
        } catch (err) {
            console.error('Error fetching nodes:', err);
            toast.error('Failed to fetch nodes.');
        }
    };

    const fetchSetTopBoxes = async () => {
        setLoading(true);
        try {
            const response = await authAxios.get('/api/settopboxes');
            const boxes = Array.isArray(response.data) ? response.data : [];
            setSetTopBoxes(boxes);
        } catch (err) {
            console.error('Error fetching set top boxes:', err);
            toast.error('Failed to fetch set top boxes.');
        } finally {
            setLoading(false);
        }
    };

    const fetchCustomers = async () => {
        try {
            const response = await authAxios.get('/api/customers');
            setCustomers(response.data);
        } catch (err) {
            console.error('Error fetching customers:', err);
            toast.error('Failed to fetch customers.');
        }
    };

    const fetchCustomerDetails = async (customerId) => {
        try {
            const response = await authAxios.get(`/api/customers/${customerId}`);
            setCustomerDetails(response.data);
            setShowCustomerModal(true);
        } catch (err) {
            console.error('Error fetching customer details:', err);
            toast.error('Failed to fetch customer details.');
        }
    };

    const handleOpenModal = (mode, box = null) => {
        setModalMode(mode);

        if (box) {
            const latestAssignment = box.assignmentHistory && box.assignmentHistory.length > 0
                ? box.assignmentHistory[box.assignmentHistory.length - 1]
                : null;

            const formDataToSet = {
                id: box._id || box.id || '',
                boxNumber: box.boxNumber || '',
                setTopBoxProvider: box.setTopBoxProvider || '',
                optionalId: box.optionalId || '',
                customerId: latestAssignment && latestAssignment.customer ? latestAssignment.customer._id : '',
                nodeId: box.node || '',
                status: box.status || 'Not Assigned',
            };

            setFormData(formDataToSet);
        } else {
            setFormData({
                id: '',
                boxNumber: '',
                setTopBoxProvider: '',
                optionalId: '',
                customerId: '',
                nodeId: '',
                status: 'Not Assigned',
            });
        }

        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    const handleCloseCustomerModal = () => {
        setShowCustomerModal(false);
        setCustomerDetails(null);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSelectChange = (selectedOption, { name }) => {
        setFormData({ ...formData, [name]: selectedOption ? selectedOption.value : '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            if (modalMode === 'create') {
                await authAxios.post('/api/settopboxes/create', {
                    boxNumber: formData.boxNumber,
                    setTopBoxProvider: formData.setTopBoxProvider,
                    optionalId: formData.optionalId,
                    status: formData.status
                });
                toast.success('Set Top Box created successfully.');
            } else if (modalMode === 'assign' || modalMode === 'edit') {
                if (!formData.id) {
                    throw new Error('Box ID is missing.');
                }
                const response = await authAxios.post('/api/settopboxes/assign', {
                    boxId: formData.id,
                    customerId: formData.customerId,
                    nodeId: formData.nodeId,
                    status: formData.status
                });

                if (response.data.message) {
                    toast.success(response.data.message);
                } else {
                    toast.success('Set Top Box updated successfully.');
                }
            }
            handleCloseModal();
            fetchSetTopBoxes();
        } catch (err) {
            console.error('Error submitting form:', err);
            if (err.response && err.response.data) {
                const errorData = err.response.data;
                let errorMessage = errorData.message || 'An unknown error occurred';
                if (errorData.error) {
                    errorMessage += `: ${errorData.error}`;
                }
                toast.error(errorMessage);
                if (errorData.stack && process.env.NODE_ENV === 'development') {
                    console.error('Error stack:', errorData.stack);
                }
            } else {
                toast.error('Failed to submit form: ' + err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    const handleBulkExport = async () => {
        const zip = new JSZip();

        for (const box of setTopBoxes) {
            try {
                // Generate QR code as a data URL
                const qrCodeDataUrl = await QRCode.toDataURL(box.boxNumber, {
                    width: 512,
                    margin: 1,
                    color: {
                        dark: '#000000',
                        light: '#FFFFFF'
                    }
                });

                // Create a new canvas
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');

                // Set canvas size (QR code + space for text)
                const qrCodeSize = 512;
                const textHeight = 100;
                canvas.width = qrCodeSize;
                canvas.height = qrCodeSize + textHeight;

                // Load QR code image
                const qrImage = new Image();
                qrImage.src = qrCodeDataUrl;

                await new Promise((resolve) => {
                    qrImage.onload = () => {
                        // Draw white background
                        ctx.fillStyle = '#FFFFFF';
                        ctx.fillRect(0, 0, canvas.width, canvas.height);

                        // Draw QR code
                        ctx.drawImage(qrImage, 0, 0);

                        // Draw text
                        ctx.fillStyle = '#000000';
                        ctx.font = 'bold 30px Arial';
                        ctx.textAlign = 'center';
                        ctx.fillText(box.boxNumber, canvas.width / 2, qrCodeSize + 40);

                        if (box.setTopBoxProvider) {
                            ctx.font = '20px Arial';
                            ctx.fillText(box.setTopBoxProvider, canvas.width / 2, qrCodeSize + 80);
                        }

                        resolve();
                    };
                });

                // Convert canvas to blob and add to zip
                canvas.toBlob((blob) => {
                    zip.file(`${box.boxNumber}.png`, blob);
                });
            } catch (err) {
                console.error('Error generating QR code for', box.boxNumber, err);
            }
        }

        // Generate and save zip file
        const content = await zip.generateAsync({ type: 'blob' });
        saveAs(content, 'SetTopBox_QRCodes.zip');
    };

    return (
        <div className="p-4">
            <h1 className="text-2xl font-bold mb-4">Set Top Box Management</h1>
            <div className="flex space-x-4 mb-4">
                <button
                    onClick={() => handleOpenModal('create')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                >
                    Add Set Top Box
                </button>
                <button
                    onClick={() => handleOpenModal('upload')}
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                >
                    Bulk Upload
                </button>
                <button
                    onClick={handleBulkExport}
                    className="bg-yellow-500 hover:bg-yellow-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition-all duration-300 ease-in-out transform hover:scale-105"
                >
                    Export All QR Codes
                </button>
            </div>

            {loading ? (
                <Loader />
            ) : (
                <SetTopBoxTable
                    setTopBoxes={setTopBoxes}
                    fetchCustomerDetails={fetchCustomerDetails}
                    handleOpenModal={handleOpenModal}
                />
            )}

            {showModal && (
                <SetTopBoxForm
                    modalMode={modalMode}
                    showModal={showModal}
                    formData={formData}
                    handleChange={handleChange}
                    handleSelectChange={handleSelectChange}
                    handleSubmit={handleSubmit}
                    customers={customers}
                    nodes={nodes}
                    handleCloseModal={handleCloseModal}
                />
            )}

            {showCustomerModal && (
                <CustomerDetailsModal
                    customerDetails={customerDetails}
                    showCustomerModal={showCustomerModal}
                    handleCloseCustomerModal={handleCloseCustomerModal}
                />
            )}

            {modalMode === 'upload' && showModal && (
                <BulkUploadModal
                    showModal={showModal}
                    handleCloseModal={handleCloseModal}
                    uploadMode={uploadMode}
                    setUploadMode={setUploadMode}
                    fetchSetTopBoxes={fetchSetTopBoxes}
                />
            )}
        </div>
    );
}

export default SetTopBoxPage;
