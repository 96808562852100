import React from 'react';

const ControlPanel = ({ onAddNode, onAddConnection, lineColor, setLineColor }) => {
    return (
        <div className="p-4 bg-white shadow-md">
            <label className="block text-gray-700 text-sm font-bold mb-2">Node Line Color:</label>
            <input
                type="color"
                value={lineColor}
                onChange={(e) => setLineColor(e.target.value)}
                className="mb-4"
            />
            <button
                onClick={onAddNode}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mb-4 w-full"
            >
                Add Node
            </button>
        </div>
    );
};

export default ControlPanel;
