// client/src/pages/NodeForm.js

import React, {useState, useEffect, useRef, useContext} from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import AuthContext from "../context/AuthContext";

const NodeForm = ({ isUpdate, nodeData, onSubmit, onClose }) => {
    const [formData, setFormData] = useState(nodeData || {
        nodeNumber: '',
        latitude: 0,
        longitude: 0,
        status: 'Active',
        hostedByType: 'Operator',
        hostedBy: '',
        boxNumber: '',
        lastServiceDate: '',
        serviceHistory: [],
    });

    const [customers, setCustomers] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState('');
    const { authAxios } = useContext(AuthContext);
    const mapRef = useRef(null);

    useEffect(() => {
        if (nodeData && nodeData.serviceHistory.length > 0) {
            const lastServiceDate = getLastServiceDate(nodeData.serviceHistory);
            setFormData({ ...nodeData, lastServiceDate });
        }
    }, [nodeData]);

    useEffect(() => {
        authAxios.get('/api/customers')
            .then(response => setCustomers(response.data))
            .catch(error => console.error('Error fetching customers:', error));
    }, [authAxios]);

    const handleCustomerChange = (e) => {
        setSelectedCustomer(e.target.value);
        setFormData({ ...formData, hostedBy: e.target.options[e.target.selectedIndex].text, boxNumber: e.target.value });
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isUpdate) {
            authAxios.put(`/api/nodes/${formData._id}`, formData)
                .then(response => onSubmit(response.data))
                .catch(error => console.error('Error updating node:', error));
        } else {
            authAxios.post('/api/nodes', formData)
                .then(response => onSubmit(response.data))
                .catch(error => console.error('Error creating node:', error));
        }
    };

    const handleGetLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                setFormData({
                    ...formData,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
                if (mapRef.current) {
                    mapRef.current.setView([position.coords.latitude, position.coords.longitude], 13);
                }
            }, (error) => {
                console.error("Error retrieving location:", error);
            });
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };

    const handleAddServiceHistory = () => {
        setFormData({
            ...formData,
            serviceHistory: [...formData.serviceHistory, { date: '', remarks: '' }]
        });
    };

    const handleServiceHistoryChange = (index, field, value) => {
        const updatedServiceHistory = [...formData.serviceHistory];
        updatedServiceHistory[index][field] = value;
        const lastServiceDate = getLastServiceDate(updatedServiceHistory);
        setFormData({ ...formData, serviceHistory: updatedServiceHistory, lastServiceDate });
    };

    const getLastServiceDate = (serviceHistory) => {
        if (!serviceHistory || serviceHistory.length === 0) return '';
        const sortedHistory = serviceHistory
            .filter(service => service.date)
            .sort((a, b) => new Date(b.date) - new Date(a.date));
        return sortedHistory[0]?.date.split('T')[0] || '';
    };

    const MapEvents = () => {
        const map = useMap();

        useEffect(() => {
            mapRef.current = map;
            map.invalidateSize();
        }, [map]);

        useEffect(() => {
            if (formData.latitude && formData.longitude) {
                map.setView([formData.latitude, formData.longitude], 13);
            }
        }, [formData.latitude, formData.longitude, map]);

        return null;
    };

    return (
        <form onSubmit={handleSubmit} className="max-h-[75vh] overflow-y-auto p-4">
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nodeNumber">
                    Node Number <span className="text-red-500">*</span>
                </label>
                <input
                    type="text"
                    name="nodeNumber"
                    id="nodeNumber"
                    value={formData.nodeNumber}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="latitude">
                    Latitude <span className="text-red-500">*</span>
                </label>
                <div className="flex">
                    <input
                        type="number"
                        step="0.000001"
                        name="latitude"
                        id="latitude"
                        value={formData.latitude}
                        readOnly
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200 cursor-not-allowed"
                    />
                    <button
                        type="button"
                        onClick={handleGetLocation}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded ml-2 focus:outline-none focus:shadow-outline"
                    >
                        Get GPS
                    </button>
                </div>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="longitude">
                    Longitude <span className="text-red-500">*</span>
                </label>
                <input
                    type="number"
                    step="0.000001"
                    name="longitude"
                    id="longitude"
                    value={formData.longitude}
                    readOnly
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200 cursor-not-allowed"
                />
            </div>
            <div className="mb-4">
                <MapContainer
                    center={[formData.latitude || 0, formData.longitude || 0]}
                    zoom={13}
                    style={{height: '200px', width: '100%'}}
                    whenCreated={(map) => {
                        mapRef.current = map;
                    }}
                >
                    <TileLayer
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    />
                    <Marker position={[formData.latitude || 0, formData.longitude || 0]}/>
                    <MapEvents/>
                </MapContainer>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="status">
                    Status <span className="text-red-500">*</span>
                </label>
                <select
                    name="status"
                    id="status"
                    value={formData.status}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                    <option value="Maintenance">Maintenance</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hostedByType">
                    Hosted By <span className="text-red-500">*</span>
                </label>
                <select
                    name="hostedByType"
                    id="hostedByType"
                    value={formData.hostedByType}
                    onChange={handleChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                    required
                >
                    <option value="Operator">Operator</option>
                    <option value="Customer">Customer</option>
                </select>
            </div>
            {formData.hostedByType === 'Customer' && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="customer">
                        Select Customer <span className="text-red-500">*</span>
                    </label>
                    <select
                        name="customer"
                        id="customer"
                        value={selectedCustomer}
                        onChange={handleCustomerChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    >
                        <option value="">Select a customer</option>
                        {customers.map((customer) => (
                            <option key={customer._id} value={customer.boxNumber}>
                                {customer.customerName}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            {formData.hostedByType === 'Customer' && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="hostedBy">
                        Hosted By (Customer Name) <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="hostedBy"
                        id="hostedBy"
                        value={formData.hostedBy}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
            )}
            {formData.hostedByType === 'Customer' && (
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="boxNumber">
                        Box Number <span className="text-red-500">*</span>
                    </label>
                    <input
                        type="text"
                        name="boxNumber"
                        id="boxNumber"
                        value={formData.boxNumber}
                        onChange={handleChange}
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        required
                    />
                </div>
            )}
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="lastServiceDate">
                    Last Service Date
                </label>
                <input
                    type="date"
                    name="lastServiceDate"
                    id="lastServiceDate"
                    value={formData.lastServiceDate}
                    readOnly
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline bg-gray-200 cursor-not-allowed"
                />
            </div>
            <div className="mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                    Service History
                </label>
                {formData.serviceHistory.map((service, index) => (
                    <div key={index} className="flex flex-col mb-2">
                        <input
                            type="date"
                            name="serviceDate"
                            value={service.date.split('T')[0]}  // Format date to yyyy-MM-dd
                            onChange={(e) => handleServiceHistoryChange(index, 'date', e.target.value)}
                            placeholder="Service Date"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline mb-2"
                        />
                        <input
                            type="text"
                            name="serviceRemarks"
                            value={service.remarks}
                            onChange={(e) => handleServiceHistoryChange(index, 'remarks', e.target.value)}
                            placeholder="Remarks"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                    </div>
                ))}
                <button
                    type="button"
                    onClick={handleAddServiceHistory}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-2"
                >
                    Add Service Entry
                </button>
            </div>
            <div className="flex items-center justify-between pt-4">
                <button
                    type="submit"
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {isUpdate ? 'Update Node' : 'Add Node'}
                </button>
                <button
                    type="button"
                    onClick={onClose}
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    Cancel
                </button>
            </div>
        </form>
    );
};

export default NodeForm;
