// client/src/pages/SubscriptionPlansPage.js

import React, { useState, useEffect, useContext } from 'react';
import Modal from '../components/Modal';
import Loader from '../components/Loader';
import { toast } from 'react-toastify';
import AuthContext from '../context/AuthContext';

function SubscriptionPlansPage() {
    const { authAxios } = useContext(AuthContext); // Access authAxios from AuthContext
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState('create');
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [planFormData, setPlanFormData] = useState({
        planName: '',
        monthlyFee: '',
        features: ''
    });
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSubscriptionPlans = async () => {
            setLoading(true);
            try {
                const response = await authAxios.get('/api/subscriptionplans');
                setSubscriptionPlans(response.data);
            } catch (err) {
                setError('Error fetching subscription plans');
                toast.error('Failed to fetch subscription plans.');
            } finally {
                setLoading(false);
            }
        };

        fetchSubscriptionPlans();
    }, [authAxios]);

    const handleOpenModal = (mode, plan = null) => {
        setModalMode(mode);
        setSelectedPlan(plan);
        if (plan) {
            setPlanFormData({
                planName: plan.planName,
                monthlyFee: plan.monthlyFee,
                features: plan.features.join(', ')
            });
        } else {
            setPlanFormData({
                planName: '',
                monthlyFee: '',
                features: ''
            });
        }
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setPlanFormData({ planName: '', monthlyFee: '', features: '' });
        setSelectedPlan(null);
    };

    const handlePlanChange = (e) => {
        const { name, value } = e.target;
        setPlanFormData({ ...planFormData, [name]: value });
    };

    const handlePlanSubmit = async (e) => {
        e.preventDefault();
        const { planName, monthlyFee, features } = planFormData;
        const planData = {
            planName,
            monthlyFee,
            features: features.split(',').map(feature => feature.trim())
        };

        try {
            if (modalMode === 'create') {
                await authAxios.post('/api/subscriptionplans/create', planData);
                toast.success('Subscription plan added successfully!');
            } else if (modalMode === 'edit') {
                await authAxios.put(`/api/subscriptionplans/${selectedPlan._id}`, planData);
                toast.success('Subscription plan updated successfully!');
            }
            const response = await authAxios.get('/api/subscriptionplans');
            setSubscriptionPlans(response.data);
            handleCloseModal();
        } catch (err) {
            toast.error('Failed to save subscription plan.');
            console.error('Error saving subscription plan:', err);
        }
    };

    const handleRemoveSubmit = async (planId) => {
        if (!window.confirm('Are you sure you want to remove this plan?')) return;
        try {
            await authAxios.delete(`/api/subscriptionplans/${planId}`);
            const response = await authAxios.get('/api/subscriptionplans');
            setSubscriptionPlans(response.data);
            toast.success('Subscription plan removed successfully!');
        } catch (err) {
            toast.error('Failed to remove subscription plan.');
            console.error('Error removing subscription plan:', err);
        }
    };

    return (
        <div>
            <div className="p-4">
                <h1 className="text-2xl font-bold mb-4">Subscription Plan Management</h1>
                <button
                    onClick={() => handleOpenModal('create')}
                    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mr-4"
                >
                    Add Subscription Plan
                </button>
                <div className="bg-white shadow-md rounded-lg p-4 mt-4">
                    {loading ? (
                        <Loader />
                    ) : error ? (
                        <div className="text-red-500">{error}</div>
                    ) : (
                        <table className="min-w-full bg-white">
                            <thead>
                            <tr>
                                <th className="py-2">Plan Name</th>
                                <th className="py-2">Monthly Fee</th>
                                <th className="py-2">Features</th>
                                <th className="py-2">Actions</th>
                            </tr>
                            </thead>
                            <tbody>
                            {subscriptionPlans.map((plan) => (
                                <tr key={plan._id} className="text-center border-t">
                                    <td className="py-2">{plan.planName}</td>
                                    <td className="py-2">{plan.monthlyFee}</td>
                                    <td className="py-2">{plan.features.join(', ')}</td>
                                    <td className="py-2">
                                        <button
                                            onClick={() => handleOpenModal('edit', plan)}
                                            className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline mr-2"
                                        >
                                            Edit
                                        </button>
                                        <button
                                            onClick={() => handleRemoveSubmit(plan._id)}
                                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded focus:outline-none focus:shadow-outline"
                                        >
                                            Remove
                                        </button>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>

            <Modal
                isOpen={showModal}
                onRequestClose={handleCloseModal}
                contentLabel={modalMode === 'create' ? "Add Subscription Plan" : "Edit Subscription Plan"}
            >
                <form onSubmit={handlePlanSubmit}>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="planName">
                            Plan Name <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="planName"
                            id="planName"
                            value={planFormData.planName}
                            onChange={handlePlanChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="monthlyFee">
                            Monthly Fee <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="number"
                            name="monthlyFee"
                            id="monthlyFee"
                            value={planFormData.monthlyFee}
                            onChange={handlePlanChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="features">
                            Features <span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            name="features"
                            id="features"
                            value={planFormData.features}
                            onChange={handlePlanChange}
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            placeholder="Comma separated features"
                            required
                        />
                    </div>
                    <div className="flex items-center justify-between pt-4">
                        <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                        >
                            {modalMode === 'create' ? "Add" : "Save"}
                        </button>
                        <button
                            type="button"
                            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </button>
                    </div>
                </form>
            </Modal>
        </div>
    );
}

export default SubscriptionPlansPage;

