// client/src/components/Modal.js

import React from 'react';

const Modal = ({ isOpen, onRequestClose, contentLabel, children, onSubmit, size = "xl", maxHeight = "80vh" }) => {
    if (!isOpen) return null;

    // Map the size prop to specific Tailwind max-w-* classes
    const sizeClassMap = {
        sm: "max-w-sm",
        md: "max-w-md",
        lg: "max-w-lg",
        xl: "max-w-xl",
        "2xl": "max-w-2xl",
        "3xl": "max-w-3xl",
        "4xl": "max-w-4xl",
        "5xl": "max-w-5xl",
        "6xl": "max-w-6xl",
        "7xl": "max-w-7xl",
        "8xl": "max-w-8xl",
        "9xl": "max-w-9xl",
        full: "max-w-full"
    };

    const modalSizeClass = sizeClassMap[size] || "max-w-xl"; // Default to xl if not specified

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50 p-4">
            <div className={`bg-white rounded-lg shadow-lg w-full ${modalSizeClass} max-h-full overflow-hidden`}>
                <div className="p-4 sm:p-6">
                    <div className="flex justify-between items-center mb-4">
                        <h2 className="text-lg sm:text-xl font-bold">{contentLabel}</h2>
                        <button
                            onClick={onRequestClose}
                            className="text-gray-500 hover:text-gray-700 text-xl"
                        >
                            &times;
                        </button>
                    </div>
                    <div
                        className="overflow-x-auto overflow-y-auto custom-scrollbar"
                        style={{ maxHeight, maxWidth: "100%" }}
                    >
                        {children}
                    </div>
                </div>
            </div>
            <style jsx>{`
                .custom-scrollbar::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }
                .custom-scrollbar::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 4px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb {
                    background: #888;
                    border-radius: 4px;
                }
                .custom-scrollbar::-webkit-scrollbar-thumb:hover {
                    background: #555;
                }
                /* For Firefox */
                .custom-scrollbar {
                    scrollbar-width: thin;
                    scrollbar-color: #888 #f1f1f1;
                }
            `}</style>
        </div>
    );
};

export default Modal;




